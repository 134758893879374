@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;700;800&family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");

//  @font-face {
//   font-family: 'Lato', sans-serif;
//   src: local("Lato-Regular"),
//    url("./fonts/Lato-Regular.ttf") format("truetype");
//   font-weight: normal;
//  }

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-family:
    "Inter",
    "Roboto",
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// Colors
// $primary-color: #533178;

// $test: rgb(204, 204, 204);

$primary-color: #8d5289;
$test: rgb(103, 185, 103);
// Buttons and Icons
$primary-color-light: #a86aa4;

$primary-light-border: #c4c4c4;
$primary-light-border-item: rgb(235, 235, 235);
$color-outline-select: #b98db7;

$primary-color-dark: #7d4979;
$primary-color-light-light: rgb(170, 132, 168);
$primary-color-light-light--hover: rgb(161, 111, 159);

$secondary-color: #5e6d9b;
$secondary-color-light: #cee2f5;

$primary-color-half: rgba(141, 82, 137, 0.5);
$primary-color-quarter: rgba(141, 82, 137, 0.25);
$primary-color-ten: rgba(141, 82, 137, 0.1);

$font-color-btn: #fff;

$primary-color--hover: #5a2436;
$color-heading-grey: rgb(67, 67, 67);
$color-heading-middle-grey: rgb(95, 95, 95);
$color-heading-light-grey: rgb(125, 125, 125);
$color-heading-super-light-grey: rgba(125, 125, 125, 0.676);
$color-heading-super-light-grey: rgb(125, 125, 125, 64%);
$color-background-main: rgba(205 210 231 / 15%);

$color-background-block: rgb(255, 255, 255);
$color-shadow-block: 4px 4px 12px 0px rgb(211, 211, 211);

$color-shadow-block-new: 3px 3px 5px 0px rgba(34, 60, 80, 0.15);
$color-shadow-block-new--hover: 3px 3px 7px 0px rgba(34, 60, 80, 0.3);
$color-shadow-block-new-large: 5px 5px 7px 0px rgba(34, 60, 80, 0.15);
$color-shadow-block-new-light: 3px 2px 5px 0px rgba(34, 60, 80, 0.1);
$color-shadow-block-new-thin: 2px 2px 3px 0px rgba(34, 60, 80, 0.2);
$color-border-block-new: rgb(243, 243, 243);
$color-shadow-item: 4px 3px 12px 0px rgb(202, 202, 202);
$color-shadow-item-downloaded: 4px 4px 12px 0px rgb(197, 197, 197);

// Shadow for buttons in Distributor item
$color-shadow-distr: 2px 2px 3px 1px rgba(0, 0, 0, 0.12);

// New Shadow for buttons in Distributor item
$color-shadow-distr-new: 2px 2px 2px 1px rgba(0, 0, 0, 0.1);

$color-red: rgb(198 18 18 / 100%);
$color-red-75: rgb(198 18 18 / 75%);
$color-red-50: rgb(198 18 18 / 50%);
$color-red-25: rgb(198 18 18 / 25%);

$color-gold: rgb(239, 197, 59);
$color-green: rgb(25, 149, 25);
$color-light-green: rgb(155, 217, 155);
$color-light-grey: rgb(192, 192, 192);
$color-light-gold: rgb(239, 209, 101);
$color-light-red: rgb(234, 161, 161);
$ocean-color: #0077b5;
$link-icon-color: #136b98;
$ocean-light-color: #8bbfde;

// Header Icons
$color-green-icon: rgb(115, 195, 115);
$color-green-icon-bg: rgb(229, 249, 229);
$ocean-light-color-bg: #e7f6ff;

$color-background-outlined: rgba(141, 82, 137, 0.04);

$color-inactive: #b6b0b6; // Dublicate in App.js

// Icons grey
$color-grey-icon: #aba3ab;
$color-grey-icon-bg: #f7f6f7;

$text-button-hover: rgba(243, 243, 243, 0.16);

$vertical-divider: rgb(199, 199, 199);

// New color Vinaty 3
$bg-top-item-section: #f3edf3;
$height-top-item-block: 30px;
$fontsz-top-item: 0.87rem;

$color-a: rgb(155, 217, 155);
$color-b: #cbe344;
$color-c: rgb(239, 209, 101);

$color-light-grey-counter: rgb(162, 162, 162);

// $blue-button-link: rgb(228, 239, 249),

// Size of distributor item component
$checkbox-item: 3%;
$name-item: 17%;
$width-signs: 10%;
$region-country-item: 14%;
$region-item: 5%;
$country-item: 8%;
$type-item: 9%;
$size-item: 7%;
$products-item: 7%;
$email-item: 9%;
$email-short-item: 4.3%;
$phone-item: 4.3%;
$linkedin-item: 4.3%;
$social-item: 4.3%;
$opener-item: 2%;

$padding-row: 1%;
$gap-in-row: 1%;

$importer-left: calc(
  $gap-in-row * 3 + $checkbox-item + $name-item + $region-item
);
$verified-left: calc($padding-row + $gap-in-row + $checkbox-item);

// $country-item: 8%;

.button--primary {
  &:hover {
    background-color: $primary-color-ten !important;
    border: 1px solid $primary-color-light !important;
    color: $primary-color-dark !important;
  }
}

.info-span {
  font-size: 0.76rem;
}

// Blur for trial text
.textshadow {
  color: transparent !important;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  transition: all 0.3s linear;
}
.not-available-link {
  position: absolute;
  display: none;
  opacity: 0;
  height: 44px;
  max-height: 44px;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 42%;
  right: auto;
  padding: 4px 8px;
  border-radius: 4px;
  width: 120px;
  background-color: #fff;
  text-align: center;
  transform: translateX(-50%);
  border: 1px solid lightgrey;
  font-size: 14px;
  line-height: 20px;
  transition: all 0.25s linear;
}
.not-available-link--active {
  // top: -65px;
  // display: flex;
  opacity: 1;
  top: -40px;
  width: 80%;
  opacity: 1;
  height: 32px;
}

.not-available-link--social {
  left: 0;
  transform: translateX(0);
  height: 32px;
  &.not-available-link--active {
    top: -40px;
    width: 80%;
  }
}
.not-available-link--personal {
  position: absolute !important;
  visibility: hidden;
  display: none;
  &.not-available-link--active {
    visibility: visible;
    display: block;
  }
}
//
.toggle-sidebar {
  display: flex;
  gap: 6%;
  font-size: 14px;
  line-height: 22px;
  // text-transform: uppercase;
  align-items: center;
  visibility: hidden;
  position: absolute;
  // left: 20px;
  top: -400px;
  width: 110px;
  height: 30px;
  box-sizing: border-box;
  text-align: center;
  // border: 1px solid $primary-color-half;
  border-radius: 2px;
  padding: 2px 6px;
  color: #fff;
  background-color: $primary-color-light;
  z-index: 99;
  cursor: pointer;
  min-width: 80px;
  opacity: 0;
  transition: all linear 0.4s;
  &.toggle-sidebar--active {
    top: -4px;
    opacity: 1;
  }
}

.close-sidebar {
  position: absolute;
  top: 30px;
  right: 10px;
  padding: 0 6px;

  display: none;
  visibility: hidden;
  opacity: 0;
  svg {
    font-size: 32px;
  }
}

// No access

.no-access {
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.no-access__text {
  position: absolute;
  margin: auto;
  font-size: 1rem;
  font-weight: bold;
  color: $color-heading-middle-grey;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  // height: 480px;
  // align-items: flex-end;
  padding-top: 3vh;
}

.preloader__logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  height: 110px;
  top: 8vh;
  transition: all linear 0.3s;
  img {
    max-width: 200px;
  }
}
.no-access__circle-loader {
  width: 30%;
  display: flex;
  justify-content: center;
  padding-top: 34vh;
  box-sizing: border-box;
  span {
    width: 290px !important;
    height: 290px !important;
    svg circle {
      stroke-width: 0.68;
    }
  }
}

// Globals small

.MuiInputBase-root.select-custom {
  color: $primary-color-light;
  transition: all ease-out 0.6s;
  :hover {
    color: $primary-color-dark;
    background-color: $color-background-outlined;
    transition: all ease-out 0.6s;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: $primary-color-half;
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $primary-color-dark;
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: $primary-color-dark;
  }
}

/* App.js */

.preloader {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;
  position: absolute;
  z-index: 199;
}

body {
  overflow: hidden;
}

.main {
  display: flex;
  justify-content: flex-start;
  // gap: 1.5%;
  background-color: $color-background-main;
  min-height: 100vh;
  overflow: clip;
  transition: all 0.4s;
  height: auto;
}

.main--notification {
  margin-top: 50px;
}

.main--disabled {
  height: 100vh;
  overflow: hidden;
  transition: all 0.4s;
}

.main__notification {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  height: 50px;
  top: -200px;
  right: 0;
  width: 100%;
  background-color: $color-light-gold;
  -webkit-box-shadow: $color-shadow-block;
  -moz-box-shadow: $color-shadow-block;
  box-shadow: $color-shadow-block;
  animation: notification-anim;
  animation-fill-mode: forwards;
  animation-duration: 0.3s;
  gap: 5px;
  div {
    display: flex;
    align-items: center;
  }
  .notification__heading {
    height: 100%;
    font-size: 0.9rem;
    font-weight: bold;
  }
  .notification__text {
    height: 100%;
    font-size: 0.9rem;
  }
}

.trial__notification {
  display: flex;
  gap: 10px;
  width: 49%;
  border-radius: 3px;
  height: 50px;
  background-color: $primary-color-light;
  color: #fff;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  // flex-wrap: wrap;
  height: 50px;
  box-sizing: border-box;
  -webkit-box-shadow: $color-shadow-block-new;
  -moz-box-shadow: $color-shadow-block-new;
  box-shadow: $color-shadow-block-new;
  .trial__text {
    font-size: 0.96rem;
  }
}

.sidebar {
  width: 260px;
  min-width: 260px;
  max-width: 260px;
  height: 100vh;
  max-height: 100vh;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  min-height: 100vh;
  border-right: 1px solid rgb(220, 220, 220);
  background-color: $color-background-block;
  box-sizing: border-box;
  z-index: 16;
  -webkit-box-shadow: $color-shadow-block-new-light;
  -moz-box-shadow: $color-shadow-block-new-light;
  box-shadow: $color-shadow-block-new-light;

  background-color: #fff;
  transition: all 0.4s;

  // Button admin dashboard
  .admin-dashboard-button {
    width: 76%;
    position: absolute;
    bottom: 10px;
    left: 14%;
    .admin-dashboard-button__text {
      font-size: 0.9rem;
      text-transform: none;
    }
  }
}

.sidebar__gradient {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  // background: rgb(141,82,137);
  // background: -moz-linear-gradient(144deg, rgba(141,82,137,0.15) 15%, rgba(141,82,137,0.1) 25%, rgba(141,82,137,0.05) 35%, rgba(0,0,0,0.00) 95%);
  // background: -webkit-linear-gradient(144deg, rgba(141,82,137,0.15) 15%, rgba(141,82,137,0.1) 25%, rgba(141,82,137,0.05) 35%, rgba(0,0,0,0.00) 95%);
  // background: linear-gradient(144deg, rgba(141,82,137,0.15) 15%, rgba(141,82,137,0.1) 25%, rgba(141,82,137,0.05) 35%, rgba(0,0,0,0.00) 95%);
}
.sidebar-absolute-slide {
  position: fixed;
  z-index: 9;
  // left: 16%;
  left: 300px;
  margin: auto 0;
  top: 50%;
  max-height: 95vh;
  // overflow-y: scroll;
  overflow-y: auto;
  border-radius: 3px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: top 0.6s;

  -webkit-box-shadow: $color-shadow-block;
  -moz-box-shadow: $color-shadow-block;
  box-shadow: $color-shadow-block;

  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #e7e7e7;
    border: 1px solid #cacaca;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: content-box;
    background-color: $primary-color-half;
  }
}

.sidebar-absolute-slide--active {
  top: 300%;
  transition: top 1s;
}

.sidebar-absolute {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  // position: absolute;
  height: auto;
  // min-height: 200px;
  margin: auto 0;
  width: 580px;
  // background-color: grey;
  right: -665px;
  z-index: 11;
  // top: 0;
  // bottom: 0;
}

// Create folder slide

.slide__folder {
  width: 430px;
  min-height: 560px;
  max-height: 560px;
  height: 560px;
  top: unset;
  bottom: 6vh;
  transform: translateY(0);
  transition: bottom 0.5s;
  padding: 14px 24px 20px 24px;
  box-sizing: border-box;
  background-color: #fff;
  &.sidebar-absolute-slide--active {
    bottom: -200%;
    transition: bottom 0.5s;
  }

  .sidebar-absolute {
    width: 100%;
    height: 100%;
    gap: 10px;
  }
}

.folder__heading-container {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 8px;
  .folder__heading {
    font-size: 0.94rem;
    font-weight: 600;
    color: $color-heading-middle-grey;
  }
}

.folder__naming-container {
  width: 100%;
  > div {
    width: 100%;
  }
  .MuiInputBase-root {
    border-radius: 3px;
  }
  .folder-name-text-field {
    border-radius: 3px;
    label {
      font-size: 0.9rem;
      line-height: 1.42rem;
    }
    input {
      font-size: 0.9rem;
      padding: 10px 14px;
    }
    & .MuiOutlinedInput-notchedOutline {
      border-color: $primary-color-quarter;
      // border-color: red;
    }
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $primary-color-half;
    }
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: $primary-color-half;
    }
    &:active .MuiOutlinedInput-notchedOutline {
      border-color: $primary-color-half;
    }
    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $primary-color-half;
    }
  }
}

.folder__color-container {
  padding: 9px 12px;
  border-radius: 3px;
  border: 1px solid $primary-color-quarter;
  margin-top: 18px;
  > button {
    border: 1px solid transparent;
    padding: 8px;
  }
  .color--active {
    border: 1px solid #e0d1de7d;
  }
  .MuiSvgIcon-root {
    width: 0.92em;
    height: 0.92em;
  }
}

.folder__color-picker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding-left: 8px;
  // gap: 10%;
  p {
    font-weight: bold;
    font-size: 0.86rem;
    font-weight: 600;
    color: $color-heading-light-grey;
    margin: 0;
  }
}

.folder__icon-container {
  padding: 10px 14px;
  border-radius: 3px;
  border: 1px solid $primary-color-quarter;
  margin-top: 20px;
  > button {
    border: 1px solid transparent;
    padding: 8px;
  }
  .icon--active {
    border: 1px solid #d6c4d47d;
    background-color: #e4d4e329;
  }
  .MuiSvgIcon-root {
    width: 0.92em;
    height: 0.92em;
  }
}

.folder__icon-picker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding-left: 8px;
  // gap: 10%;
  p {
    font-weight: bold;
    font-size: 0.86rem;
    color: $color-heading-light-grey;
    margin: 0;
  }
}

.folder__button-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  button {
    border-radius: 2px;
  }
  .folder__button-delete {
    padding-left: 14px;
    padding-right: 14px;
  }
  .folder__button-delete:hover {
    color: red;
    background-color: rgb(246, 246, 246);
  }
}

// Folders list

.folders {
  width: 100%;
  .folders__folders {
    text-align: center;
    display: flex;
    width: 100%;
    font-size: 0.8rem;
    // justify-content: flex-start;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-left: 7%;
    width: 93%;
    box-sizing: border-box;
    padding-left: 9%;
    border-left: 1px solid $primary-color-quarter;

    min-height: 60px;
    display: flex;
    flex-direction: column;
    // background: #f3f3f3;
    // min-width: 341px;
    // border-radius: 5px;
    padding: 0 15px 0 15px;
    margin-right: 45px;
  }
}

.folders__draggable-context {
  width: 95%;
  position: relative;
}

.folders__span {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  color: $color-heading-light-grey;
  margin-top: 8px;
}

.folders__item {
  width: 100%;
  box-sizing: border-box;
  margin: 14px 0;
  padding: 2px 6px;
  cursor: pointer !important;
  white-space: nowrap;
  overflow-x: hidden;
  // button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  height: 38px;
  // color: $color-heading-middle-grey;
  color: $primary-color-dark;
  padding-left: 16px;
  padding-right: 10px;
  // background-color: $primary-color-ten;
  background-color: transparent;
  border: 1px solid rgba(141, 82, 137, 0.25);
  border-radius: 2px;
  // margin-bottom: 20px;
  transition: all 0.4s;
  &:hover {
    background-color: $primary-color-ten !important;
    border: 1px solid transparent;
  }
  .folders__item-name {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  svg {
    font-size: 1.7em !important;
    cursor: move;
  }
  &.folder--active {
    border: 1px solid transparent;
    background-color: $primary-color-ten !important;
    outline: 2px solid $primary-color-half;
  }
  // }
}
.folders__item:first-child {
  margin-top: 0;
}

.folders__item--blink {
  border: 1px solid $primary-color-half;
  -webkit-box-shadow: 0px 0px 16px 0px rgba(141, 82, 137, 0.4);
  -moz-box-shadow: 0px 0px 16px 0px rgba(141, 82, 137, 0.4);
  box-shadow: 0px 0px 16px 0px rgba(141, 82, 137, 0.4);
  transition: all 0.5s;
}

.folders__edit {
  position: absolute;
  right: -15%;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer !important;
  svg {
    font-size: 18px !important;
    color: $primary-color-half;
  }
  :hover {
    color: $primary-color;
  }
}

// Modal Limit Notification
.content__limit-note {
  display: flex;
  visibility: hidden;
  top: -50vh;
  flex-direction: column;
  position: fixed;
  transform: translate(-50%, -50%);
  // top: 50vh;
  left: 50%;
  width: 30%;
  padding: 18px 2%;
  background-color: #fff;
  border-radius: 6px;
  z-index: 999;
  transition: all ease-in 0.4s;

  -webkit-box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.15);

  &.content__limit-note--active {
    visibility: visible;
    top: 50vh;
  }
  .limit-note__heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .limit-note__close {
    position: absolute;
    top: -64px;
    right: 0;
    button {
      background-color: #ffffff61;
      color: $color-heading-light-grey;
      transition: all linear 0.3s;
      &:hover {
        background-color: #ffffffa4;
        transform: rotate(180deg);
      }
    }
  }
  .limit-note__heading .limit-note__h-text {
    font-weight: bold;
    font-size: 1.1rem;
    margin: 8px 0 15px 0;
  }
  .limit-note__text {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

// Content block

.content__block {
  padding: 0 $padding-row;
  background-color: $color-background-block;

  border-radius: 6px;

  -webkit-box-shadow: $color-shadow-block;
  -moz-box-shadow: $color-shadow-block;
  box-shadow: $color-shadow-block;
}

.content__block--item {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 12px 0;
  height: 100%;
  min-height: 100%;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid $primary-light-border-item;
  -webkit-box-shadow: $color-shadow-block-new;
  -moz-box-shadow: $color-shadow-block-new;
  box-shadow: $color-shadow-block-new;
}

.content__block--item--hidden {
  visibility: hidden;
  max-width: 0;
  max-height: 0;
  min-height: 0;
  height: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
  div,
  span {
    visibility: hidden;
    max-width: 0;
    max-height: 0;
    min-height: 0;
    height: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
  }
}

.content__block--item--preview {
  &:before {
    content: "Preview";
    display: flex;
    justify-content: center;
    width: 100px;
    height: 20px;
    position: absolute;
    left: -3.8%;
    top: 0;
    margin-top: 24px;
    transform: rotate(-90deg);
    font-size: 0.8rem;
    color: grey;
  }
}

.content__block--item.item--downloaded.content__block--item--preview,
.content__block--item.item--viewed.content__block--item--preview {
  &:before {
    display: none !important;
  }
}

.item--viewed {
  // background-color: #2e94cb10;
  position: relative;
  transition: all ease-in 0.3s;
  nav.MuiList-root {
    background-color: transparent;
    > div {
      background-color: transparent;
    }
  }
}
.item--downloaded {
  // background-color: rgba(61, 170, 88, 0.054);
  position: relative;
  transition: all ease-in 0.3s;
  nav.MuiList-root {
    background-color: transparent;
    > div {
      background-color: transparent;
    }
  }
  -webkit-box-shadow: $color-shadow-item-downloaded;
  -moz-box-shadow: $color-shadow-item-downloaded;
  box-shadow: $color-shadow-item-downloaded;
}

.item__action-signs {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: $width-signs;
  min-width: $width-signs;
  padding-right: 15px;
  &:after {
    position: absolute;
    content: "";
    top: -25px;
    right: -5px;
    height: 77px;
    min-height: 100%;
    width: 2px;
    border-left: 1px solid rgb(230, 230, 230);
  }
}

.action-signs__icons {
  // position: absolute;
  // left: 1.5% + $checkbox-item + $name-item;
  // transform: translate(-20px);
  // top: 0;
  display: flex;
  gap: 8px;
  // flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  min-width: 120px;
  height: 28px;
  opacity: 0;
  animation: signs-appear;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 100ms;
  .item__downloaded-sign,
  .item__viewed-sign {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    min-width: 30px;
    box-sizing: border-box;
    border-radius: 2px;
  }
  .item__downloaded-sign {
    background-color: #199c3a17;
    cursor: pointer;
    transition: all linear 0.24s;
    svg {
      color: #199c3a97;
      width: 1.24rem;
      height: 1.24rem;
    }
    &:hover {
      background-color: #1ea84133;
    }
  }
  .item__viewed-sign {
    background-color: #2390cb1d;
    svg {
      color: #2390cb8d;
      width: 1.18rem;
      height: 1.18rem;
    }
  }
}
.class {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 23px;
  padding-right: 4px;
  border-radius: 2px;
  font-weight: 500;
  font-size: 0.78rem;
  margin-left: 10px;
  .class__letter {
    padding: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 0.87rem;
    font-weight: 700;
    margin-right: 5px;
  }
}
.class-a {
  border: 1px solid $color-a;

  color: $color-heading-middle-grey;
  .class__letter {
    background-color: $color-a;
    color: #fff;
  }
}
.class-b {
  border: 1px solid $color-b;

  color: $color-heading-middle-grey;
  .class__letter {
    background-color: $color-b;
    color: #fff;
  }
}
.class-c {
  border: 1px solid $color-c;

  color: $color-heading-middle-grey;
  .class__letter {
    background-color: $color-c;
    color: #fff;
  }
}

.item__container-top {
  display: flex;
  justify-content: flex-start;
  gap: $gap-in-row;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 74px;
  font-size: 0.95rem;
  > div {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
  }

  span.no-info {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.78rem;
    text-align: center;
    color: $color-heading-middle-grey;
    height: 32px;
    width: 50%;
    padding: 0 4px;
    border-radius: 3px;
    background-color: $primary-color-ten;
    margin: 0 auto;
  }
}

.question-toggle {
  padding-top: 2px;
  // margin-left: 2px;
  // font-size: 0.9rem !important;
  width: 0.92rem;
  height: 0.92rem;
  cursor: pointer;
  &:hover {
    path {
      color: #8f8f8f;
      transition: color linear 0.2s;
    }
  }
  path {
    color: $color-heading-super-light-grey;
    transition: color linear 0.2s;
  }
}

.question-info {
  top: 0;
  position: absolute;
  padding: 7px 16px;
  background-color: #fff;
  border: 1px solid rgb(233, 233, 233);
  -webkit-box-shadow: $color-shadow-block-new-large !important;
  -moz-box-shadow: $color-shadow-block-new-large !important;
  box-shadow: $color-shadow-block-new-large !important;
  border-radius: 3px;
  // transform: translateX(-75%);
  z-index: 20;
  .class {
    margin: 0;
  }
  .question-info__heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.86rem;
    gap: 15px;
    position: relative;
    margin: 8px 0;
    svg {
      margin-right: 0;
      width: 20px;
      height: 20px;
      path {
        color: $primary-color;
      }
    }
  }

  .question-info__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 21;
    svg {
      margin-right: 0;
    }
  }

  .question-info__intro {
    font-size: 0.8rem;
  }

  .question-info__text {
    font-size: 0.86rem;
    line-height: 1.24rem;
    font-weight: 500;
    margin: 8px 0;
  }
  &.question-info--status {
    top: 0;
    left: 150px;
    width: 360px;
  }
  &.question-info--types {
    top: 0;
    left: 80px;
    width: 360px;
  }
  &.question-info--contacts {
    left: 110px;
    width: 240px;
    .question-info__heading {
      margin-top: 10px;
    }
    .question-info__text {
      margin-top: 14px;
    }
    .question-info__close {
      top: 5px;
      right: 6px;
      z-index: 21;
      svg {
        margin-right: 0;
      }
    }
  }
}

.question-info--personal-email {
  transform: translateX(72%);
  padding: 10px 25px;
  width: 450px;
  > div.MuiCollapse-wrapper {
    padding: 10px 0;
  }
  .question-info__intro {
    margin: 0;
    font-size: 0.95rem;
  }
}

.question-info--personal-email {
  .question-info__close {
    top: 0 !important;
  }
}

.question-toggle--personal-email {
  padding-top: 2px;
  margin-left: 8px;
  cursor: pointer;
  path {
    color: $color-heading-super-light-grey;
  }
}

.content__block--item:nth-last-child(-n + 3) {
  .question-info {
    top: unset;
    bottom: 0;
    transform: translate(105%, 50%);
  }
}
.content__block--item:last-of-type {
  .question-info {
    transform: translate(105%, 0);
  }
}

//
// Sidebar
//
//\\//\\//\\ Container of Logo + User Info

.sidebar__top-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  // height: 325px;
  // min-height: 325px;
  margin: 0 0 40px 0;
  box-sizing: border-box;
  // border-bottom: 1px solid $primary-color-half;
  padding: 0;
}

//\\//\\//\\ Logo

.sidebar__vinaty {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  // padding: 26px 0 10px 36px;
  padding: 26px 0 10px 0px;
  // min-height: 100px;
  box-sizing: border-box;
  img {
    width: 135px;
  }
}

.sidebar__counter {
  display: flex;
  width: 70%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid $primary-color-half;
  border-top: 1px solid $primary-color-half;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 400;
  font-size: 0.88rem;
}

//\\//\\//\\ Menu in Sidebar
.sidebar__center {
  width: 82%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 18px;
  > button {
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 15px;
    height: 40px;
    gap: 12px;
    font-size: 0.96rem;
    border-radius: 2px;
    .MuiButton-startIcon {
      svg {
        font-size: 1.35rem;
      }
      max-width: 23px;
      min-width: 23px;
    }
  }

  .button-saved-search {
    .MuiButton-startIcon svg {
      font-size: 1.9rem;
      margin-left: -1px;
    }
  }
}

.sidebar__button {
  width: 100%;
  font-size: 18px;
  font-weight: 600 !important;
  padding: 0 10px;
  color: $color-heading-light-grey !important;
  line-height: 0.96rem !important;
  &:hover {
    background-color: $primary-color-ten !important;
    color: $primary-color !important;
  }
  .counter-button {
    margin-left: -6px;
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: normal;
  }
  .MuiButton-endIcon {
    margin-left: auto;
    margin-right: 10px;
    font-size: 1em;
    .end-arrow {
      transform: rotate(90deg);
      transition: transform 0.35s;
    }
  }
}

.sidebar__create-folder {
  font-size: 0.8rem;
}

.sidebar__button--active {
  background-color: $primary-color-ten !important;
  color: $primary-color !important;
}

.sidebar__button--opened {
  .MuiButton-endIcon .end-arrow {
    transform: rotate(270deg);
    transition: transform 0.35s;
  }
}
// .download__container {
//   display: none;
//   visibility: hidden;
//   height: auto;
//   &.download__container--active {
//     display: flex;
//     visibility: visible;
//     justify-content: center;
//     align-items: center;
//   }

// }
// .download__link {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 0;
//   transition: all 0.3s linear;
//   &.download__link--active {
//     height: 20px;
//     // transition: all 0.3s linear;
//     a {
//       // transition-delay: 1s;
//       // transition-property: height, opacity;
//       // opacity: 1;
//       // transition: all 0.3s linear;
//       font-size:0.9rem;
//       text-decoration: underline;
//       cursor: pointer;
//       color: $primary-color;
//       &:hover {
//         color: $primary-color--hover;
//       }
//     }
//   }
// }

.header {
  position: relative;
  box-sizing: border-box;
  padding: 0 2%;
}

//\\//\\ Sidebar User Saves Opened //\\//\\//
.saves-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin: 5px 0 14px 0;
  p {
    font-weight: bold;
    color: $color-heading-light-grey;
    font-size: 0.94rem;
  }
}
.close-saves {
  // position: absolute !important;
  // right: 20px;
  // top: -30px;
}

.user-saves {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  padding: 18px 15px 15px 24px;
  border-radius: 3px;
  width: 100%;
  height: auto;
  min-height: 100px;
  // top: -125px;
  position: relative;
  box-sizing: border-box;
}

.user-saves__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.94rem;
  font-weight: bold;
  color: $color-heading-light-grey;
  margin: auto 0;
  margin-bottom: 20px;
}

.user-saves__item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  position: relative;
  gap: 3%;
  white-space: nowrap;
  border-bottom: 1px solid $primary-color-quarter;
  // &:last-of-type{
  //   border-bottom: 0;
  // }
  .user-saves__info {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 66%;
    gap: 5%;
    overflow-x: hidden;
    position: relative;
    z-index: 11;
    .user-saves__name {
      display: inline-flex;
      position: relative;
      font-size: 0.9rem;
      width: 55%;
      max-width: 55%;
      min-width: 55%;
      overflow: hidden;
      // text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 500;
      color: $color-heading-middle-grey;
    }
    // .user-saves__name:before {
    //   content:'';
    //   width: 12px;
    //   height:100%;
    //   position:absolute;
    //   right:0;
    //   top:0;
    //   background:linear-gradient(transparent 0px, white);
    // background-image : -webkit-linear-gradient(left, rgba(255,255,255, 0), 255,255,255);
    // background-image : linear-gradient(left, rgba(255,255,255, 0), 255,255,255);
    // }

    .user-saves__date-container {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 40%;
      max-width: 40%;
      min-width: 40%;

      .user-saves__date {
        display: flex;
        align-items: center;
        font-size: 0.76rem;
        padding: 0 8px;
        height: 28px;
        background-color: $primary-color-ten;
        color: $primary-color;
        border-radius: 2px;
        padding-top: 1px;
      }
    }
  }

  .user-saves__buttons {
    .user-saves__save {
      border: none !important;
      outline: none !important;
      height: 24px !important;
      line-height: 1rem !important;
    }
    .user-saves__save:hover {
      border: none !important;
      outline: none !important;
      height: 24px !important;
      line-height: 1rem !important;
    }
    // width: 34%;
    // height: 40px;
    // display: flex;
    // justify-content: space-evenly;
    // align-items: center;
    .user-saves__delete {
      margin-right: 10px;
      svg {
        font-size: 1.3rem;
      }
    }
    .user-saves__save {
      text-transform: none;
      font-size: 0.84rem;
      border-radius: 2px;
      height: 30px;
      max-height: 30px;
      min-height: 30px;
      -webkit-box-shadow: $color-shadow-block-new;
      -moz-box-shadow: $color-shadow-block-new;
      box-shadow: $color-shadow-block-new;
      &:hover {
        -webkit-box-shadow: $color-shadow-block-new--hover;
        -moz-box-shadow: $color-shadow-block-new--hover;
        box-shadow: $color-shadow-block-new--hover;
      }
      .MuiButton-endIcon svg {
        font-size: 0.9rem;
      }
    }
  }
}
.user-saves__item:nth-last-child(1) {
  border-bottom: none;
}
//\\//\\ Section of heading of fields top //\\//\\

.results__headings {
  display: flex;
  padding: 0 $padding-row;
  // box-sizing: border-box;
  position: relative;

  .question-toggle {
    margin-left: 7px;
  }

  .results__container-headings {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin-top: 6px;
    gap: $gap-in-row;

    > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      box-sizing: border-box;
      font-size: 0.76rem;
      font-weight: 600;
      color: $color-heading-light-grey;
      position: relative;
    }
  }

  .headings__name {
    width: $name-item;
    min-width: $name-item;
    padding-left: 10px;
  }
  .headings__action-signs {
    width: $width-signs;
    min-width: $width-signs;
    position: relative;
    justify-content: flex-end !important;
    padding-right: 14px;
  }
  .headings__region-country {
    width: $region-country-item;
    min-width: $region-country-item;
    padding-left: 15px;
  }
  .headings__region {
    width: $region-item;
    min-width: $region-item;
    padding-left: 15px;
  }
  .headings__country {
    width: $country-item;
    min-width: $country-item;
  }
  .headings__size {
    width: $size-item;
    min-width: $size-item;
  }
  .headings__type {
    width: $type-item;
    min-width: $type-item;
    gap: 0;
  }
  .headings__products {
    width: $products-item;
    min-width: $products-item;
  }
  // .headings__website {
  //   width: $website-item;
  //   min-width: $website-item;
  // }
  // .headings__updated {
  //   width: $date-item;
  //   min-width: $date-item;
  // }
  .headings__emails {
    width: $email-short-item;
    min-width: $email-short-item;
    padding-left: 15px;
  }

  .headings__phones {
    width: $phone-item;
    min-width: $phone-item;
  }
  .headings__linkedin {
    width: $linkedin-item;
    min-width: $linkedin-item;
  }
  .headings__social {
    width: $social-item;
    min-width: $social-item;
  }
  // .headings__action {
  //   width: $action-item;
  //   min-width: $action-item;
  //   // padding-left: 15px;
  // }
  .headings__opener {
    width: $opener-item;
    min-width: $opener-item;
    margin-left: auto;
    justify-content: center !important;
    padding-right: 2%;
  }
}

//\\//\\ Results Not Found //\\//\\

.results__nothing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0 50px 0;
  p {
    display: inline-block;
    font-size: 22px;
    font-weight: bold;
    color: $color-heading-light-grey;
    margin: 40px 0;
  }
}

.nothing__clear {
  margin: 0 0 40px 0 !important;
}

//\\//\\ Results Expired //\\//\\

.results__expired {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0 10px 0;

  padding: 3vh 2% 6vh 2%;
  background-color: $color-background-block;

  border-radius: 6px;

  border: 2px solid transparent;

  -webkit-box-shadow: $color-shadow-block;
  -moz-box-shadow: $color-shadow-block;
  box-shadow: $color-shadow-block;

  // transition: all 0.4s;
  .expired__heading {
    display: inline-block;
    font-size: 1.8rem;
    font-weight: bold;
    color: $color-heading-light-grey;
    margin: 20px 0;
  }
  .expired__text {
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 500;
    color: $color-heading-grey;
    margin: 10px 0 30px 0;
  }
}

// Free trial banner limit
.limit__heading {
  font-size: 1.5rem;
  font-weight: bold;
  color: $color-heading-middle-grey;
  margin-bottom: 5px;
}

.limit__text {
  width: 50%;
  font-size: 1rem;
  line-height: 1.8rem;
  text-align: center;
}

.limit__buttons {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 2%;
  margin-top: 10px;
  margin-bottom: 35px !important;
  a {
    border-radius: 3px;
  }
}

//\\\// Section of item fields TOP

.item__checkbox,
.headings__checkbox {
  width: $checkbox-item;
  min-width: $checkbox-item;
}
.item__checkbox {
  .MuiButtonBase-root {
    padding: 4px;
  }
  // .MuiSvgIcon-root {
  //   color: $color-heading-light-grey;
  // }
  // .Mui-checked {
  //   .MuiSvgIcon-root {
  //     color: $primary-color-light;
  //   }
  // }
}
// .headings__checkbox .checkbox__input {
//   padding: 6px;
//   svg {
//     font-size: 34px;
//   }
// }

.item__bookmark {
  // width: $bookmark-item;
}

.item__name {
  width: $name-item;
  min-width: $name-item;
  max-width: $name-item;
  font-weight: 600;
  color: $color-heading-middle-grey;
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  transition: all 0.3s linear;

  .item__new-market {
    position: absolute;
    top: -8px;
    left: 38px;
    font-size: 0.66rem;
    padding: 0 6px;
    border-radius: 2px;
    background-color: $color-green-icon-bg;
    color: $color-green-icon;
  }

  .item__company-trade-name {
    position: relative;
    font-size: 0.92rem;
    transition: color linear 0.2s;
    &:hover {
      color: $primary-color;
      cursor: pointer;
    }
  }
  .item__company-trade-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    transition: all linear 0.2s;
  }
}

.item__region {
  width: $region-item;
  font-size: $fontsz-top-item;
  padding-left: 15px;
}
.item__size,
.item__region,
.country__text {
  color: #343434;
}
.item__country {
  width: $country-item;
  gap: 10px;
  // padding-left: 10px;
  .country__text {
    font-size: $fontsz-top-item;
  }
  // > img {
  //   margin-right: 15px;
  // }
}
.item__size {
  width: $size-item;
  height: $height-top-item-block;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
  // padding-right: 15px;
  padding-right: 1%;
  .item__company-size {
    display: flex;
    align-items: center;
    justify-content: flex;
    gap: 8px;
  }
  .item__size-icon,
  .item__employees-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $height-top-item-block;
    background-color: $primary-color-ten;
    height: $height-top-item-block;
    border-radius: 2px;
    // margin-right: 8px;
    // padding: 3px 2px;
    // box-sizing: border-box;
    svg {
      width: 1.5rem;
      height: 1.5rem;
      path {
        color: $primary-color;
        height: 20px;
        width: auto;
      }
    }
  }
  .item__employees-icon {
    svg {
      width: 0.9em;
      height: 0.9em;
      font-size: 1.2rem;
      margin-top: 2px;
    }
  }
  .item__size-label {
    font-size: $fontsz-top-item;
  }
  // &.item__size--small {
  //   gap: 10px;
  //   .item__size-label {
  //     font-size: 0.9rem;
  //   }
  // }
}

.item__size:after {
  position: absolute;
  content: "";
  top: -25px;
  right: -5px;
  height: 77px;
  min-height: 100%;
  width: 2px;
  border-left: 1px solid rgb(230, 230, 230);
}

.item__company-employees {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 0.9rem;
  > svg.MuiSvgIcon-root {
    path {
      color: $primary-color;
    }
  }
}
.item__type {
  width: $type-item;
}
.item__products {
  width: $products-item;
}

.item__type,
.item__products {
  flex-direction: column;
  justify-content: flex-start;
  min-height: $height-top-item-block;
  .item__type-icon,
  .item__products-icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 28px;
    border-radius: 3px;
    margin-right: 4px;
    box-sizing: border-box;
    .icon-organic {
      width: 0.9rem;
      height: 0.9rem;
      margin-left: 4px;
    }
    svg {
      path {
        color: $primary-color;
      }
    }
  }
  .item__type-text,
  .item__products-text {
    font-size: $fontsz-top-item;
    color: $color-heading-grey;
  }
}
.type__container,
.products__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  padding: 4px 6px;
  height: auto;
  min-height: 24px;
  background-color: $bg-top-item-section;
  border-radius: 2px;
  z-index: 5;
}
.type__first,
.type__other,
.products__first,
.products__other {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 100%;
  height: 24px;
  min-height: 24px;
  .type__opener,
  .products__opener {
    margin-left: auto;
    padding: 0;
    width: 24px;
    height: 24px;
    svg {
      transform: rotate(-90deg);
    }
    .type__counter,
    .products__counter {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px;
      width: 20px;
      height: 20px;
      border-radius: 2px;
      background-color: $primary-color-light-light;
      color: #fff;
      font-size: 0.7rem;
      transition: all linear 0.2s;
      &:hover {
        background-color: $primary-color-light;
      }
    }
  }
}
.type__other,
.products__other {
  margin-top: 7px;
  justify-content: flex-start;
}
.type__other:not(:first-child),
.products__other:not(:first-child) {
  margin-top: 8px;
}
.type__other:last-child,
.products__other:last-child {
  margin-bottom: 3px;
}
.type__list,
.products__list {
  width: 100%;
}
.products__other {
  .item__products-icon svg {
    margin-left: 2px;
  }
}
.icon-beer {
  path {
    color: #ad76a8 !important;
  }
}
.e-commerce-force {
  // width: 1.2rem;
  font-size: 1.2rem !important;
  // margin: 0 auto;
}
.button-custom-shadow {
  text-transform: none !important;
  -webkit-box-shadow: $color-shadow-distr !important;
  -moz-box-shadow: $color-shadow-distr !important;
  box-shadow: $color-shadow-distr !important;
}

.item__emails {
  width: $email-item;
  padding-left: 15px;
}
.item__emails-short {
  width: $email-short-item;
}

.email__counter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  width: 20% !important;
  min-width: 20px;
  height: 20px;
  border-radius: 2px;
  background-color: #fff;
  color: #fff;
  font-size: 0.78rem;
  font-weight: 600;
  &.email__counter--green {
    color: $color-light-green;
  }
  &.email__counter--external {
    color: $ocean-light-color;
  }
  &.email__counter--withnotes {
    color: $color-light-gold;
  }
  &.email__counter--invalid {
    color: $color-light-red;
  }
  &.email__counter--noemail {
    color: $color-light-grey;
  }
}
.item__phones,
.item__linkedin,
.item__social,
.item__emails-short {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $phone-item;
  height: $height-top-item-block;
  min-height: $height-top-item-block;
  .contacts-counter__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;
    height: 100%;
    background-color: $bg-top-item-section;
    border-radius: 2px;
    padding: 0 5px;
    .contacts-counter__icon {
      // height: $height-top-item-block;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        height: 100%;
        width: 1.4rem;
        height: 1.4rem;
        path {
          color: $primary-color-light-light;
          // height: 20px;
          // width: auto;
        }
        // height: $height-top-item-block;
      }
    }
    .contacts-counter__counter {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      font-size: 0.8rem;
      font-weight: 500;
      border-radius: 2px;
      color: #fff;
      background-color: $primary-color-light-light;
    }
  }
}
.contacts-counter-zero {
  &.contacts-counter__container {
    background-color: rgb(238, 238, 238);
  }
  .contacts-counter__icon {
    svg {
      path {
        color: $color-light-grey-counter !important;
      }
    }
  }
  .contacts-counter__counter {
    background-color: $color-light-grey-counter !important;
  }
}

.item__emails-short {
  margin-left: 0.8%;
}
.company__action--folders {
  position: relative;
  width: 248px;
  height: 40px;
  margin-top: 25px;
  margin-bottom: 10px;
  z-index: 10;
  .MuiCollapse-root {
    position: relative;
    z-index: 100;
  }
  .distributor__actions {
    width: 100%;
    height: 100%;
  }
  .distributor__folder-action {
    height: 100%;
    padding: 0 5px 0 14px;
    box-sizing: border-box;
    background-color: rgb(248, 248, 248);
    transition: all linear 0.2s;
    &:hover {
      background-color: rgb(242, 239, 241);
    }
  }
  .css-cveggr-MuiListItemIcon-root {
    min-width: 40px;
  }
  .MuiList-root {
    width: 100%;
    background-color: rgb(248, 248, 248);
    z-index: 99;
  }
  .MuiTypography-root {
    font-size: 0.92rem;
    color: $color-heading-middle-grey;
  }
  .distributor__actions .folder-action-btn {
    border-top: 1px solid rgb(230, 230, 230);
  }
}

.item__action {
  position: relative;
  // width: $action-item;
  display: flex;
  align-items: center;
  button {
    border-radius: 3px !important;
  }
  .distributor__actions {
    width: 100%;
    > nav {
      width: 100%;
      > .MuiListSubheader-root {
        line-height: 36px;
        padding: 0;
      }
    }
    nav > div.MuiCollapse-root {
      top: 50px;
      border: 1px solid $primary-color-quarter;
      border-radius: 4px;
    }
    .MuiCollapse-root {
      width: 260px;
      position: absolute;
      display: block;
      background-color: #fff;
      z-index: 10;
      left: -1px;
    }
    .collapsed-inside {
      // border: 1px solid $primary-color-quarter;
      border-radius: 4px;
      border-top: 0;
      right: -2px;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }

  .distributor__folder-action--inactive {
    cursor: default;
    background-color: #f5f5f5;
    color: #d0cbd0;
    svg {
      color: #d0cbd0;
    }
  }
}

.distributor__actions {
  .folder-action-btn {
    margin: 0 !important;
    // padding: 8px 16px !important;
  }
}

.folder-action-btn {
  display: inline-flex;
  z-index: 2;
  background-color: rgb(248, 248, 248) !important;
  // border: 1px solid rgb(232, 232, 232) !important;
  // border-radius: 3px !important;
  &:hover {
    background-color: rgb(242, 239, 241) !important;
    // border: 1px solid rgb(215, 215, 215) !important;
    transition: all 0.2s;
  }
  .MuiListItemText-root {
    white-space: nowrap;
    .MuiTypography-root {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .css-cveggr-MuiListItemIcon-root {
    min-width: 35px;
  }
  .css-1fqgk7o-MuiListItemIcon-root {
    min-width: 35px;
  }
  .MuiTypography-root {
    font-size: 0.9rem;
    color: $color-heading-middle-grey;
  }
}

.folder-container {
  position: relative;
  margin-bottom: 5px;
  z-index: 3;
  background-color: #fff;
}

.folder-action-span {
  display: none;
  position: absolute;
  width: 150px;
  left: -180px;
  top: -4px;
  transform: translateY(35%);
  align-items: center;
  justify-content: center;
  height: 26px;
  padding: 0 8px 2px 8px;
  background-color: $primary-color-light;
  border-radius: 2px;
  color: #fff;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.3s;
  z-index: 0;
  font-size: 0.846rem;
}
.folder-action-span--blink {
  // left: -170px;
  opacity: 1;
  transition: opacity 0.5s;
  z-index: 0;
}

.remove-folder-icon {
  svg {
    font-size: 22px;
  }
}

.item__opener {
  width: $opener-item;
  min-width: $opener-item;
  margin-left: auto;
  margin-right: 1%;
  > button {
    padding: 7px;
  }
  &.item__opener--closed {
    > button {
      background-color: #f0ecef;
    }
    svg {
      transform: rotate(0deg);
    }
  }
}

.item__button-icon-open {
  svg {
    transform: rotate(90deg);
    transition: all 0.4s;
  }
}

.item__button-icon-open.item__button-icon-open--open {
  svg {
    transform: rotate(270deg);
    transition: all 0.4s;
  }
}

.item__button-bookmark {
  svg {
    // color: $color-heading-super-light-grey;
    color: $primary-color-light-light;
  }
}

.item__button-bookmark--active {
  svg {
    color: $primary-color-light;
  }
}

.item__container-bottom {
  height: 300px;
  min-height: 300px;
  width: 285px;
  border-top: 1px solid rgb(201, 201, 201);
  font-size: 0.95rem;
  color: $color-heading-grey;
  // padding: 0 12px;
  width: 100%;
  box-sizing: border-box;
  .MuiCollapse-wrapper {
    padding: 0 0 10px 0;
  }
  .MuiCollapse-wrapperInner {
    display: flex;
    justify-content: flex-start;
    gap: 0;
  }

  .company__single-bottom-info {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
    min-height: 59px;
    box-sizing: border-box;
    gap: 5px;
    svg {
      margin-right: 6px;
      color: $primary-color-light;
      font-size: 20px;
      min-width: 20px;
    }
  }

  .company__single-bottom-info--founded {
    padding-top: 5px;
    min-height: 46px;
  }

  .single-bottom-info__no-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $color-heading-grey;
    padding-left: 8px;
    // svg {

    // }
  }

  .item__btm-link {
    position: relative;
    color: $color-heading-middle-grey;
    gap: 1.5em;
    svg {
      color: $link-icon-color;
      font-size: 20px;
    }
    .MuiButton-containedInsta_light {
      color: #87416f;
      svg {
        color: #6e2f58;
        font-size: 22px;
      }
    }
    button {
      border-radius: 3px;
    }
    .MuiButton-containedX_light {
      color: #2f2f2f;
      svg {
        color: #2c2c2c;
        font-size: 22px;
      }
    }
  }

  .global-flag {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6px;
    height: 20px;
    border-radius: 3px;
    font-size: 12px;
    background-color: #c3e2f3;
    color: #2e4456;
  }

  .company__btm-folders {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    line-height: 30px;
    padding: 0 15px 0 14px;
    box-sizing: border-box;
    color: $color-heading-middle-grey;
    margin-top: 6px;
    // max-width: 200px;
    svg {
      // color: $primary-color-light-light;
      margin-right: 6px;
      margin-left: 3px;
      // font-size: 1.2rem;
    }
    > span {
      font-size: 0.92rem;
      margin-right: 5px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .company__btm-folders--empty {
    width: 248px;
    svg {
      margin-right: 18px;
      color: $primary-color-light-light;
      font-size: 1.2rem;
    }
  }
  .company__btm-actions {
    margin-top: 25px;
    margin-bottom: 15px;
    padding-left: 14px;
  }
  .item__btm-address {
    flex-wrap: wrap;
    overflow-x: hidden;
    gap: 0;
    row-gap: 7px;
    padding-bottom: 5px;
    min-height: 46px;
    span {
      white-space: nowrap;
      margin-left: 5px;
      transition: all 0.3s linear;
    }
  }
  .item__btm-email {
    button {
      border-radius: 3px;
    }
    height: 60px;
    max-height: 60px;
    min-height: 60px;
    position: relative;
    gap: 4%;
    svg {
      color: #fff;
    }
    > .MuiCollapse-root {
      position: absolute;
    }
    // > button {
    //   height: 30px;
    //   max-height: 30px;
    //   min-height: 30px;
    // }
  }

  .item__btm-email--no_email {
    display: flex;
    padding: 0 16px;
    height: 30px;
    color: $primary-color;
    border: 1px solid $primary-color-half;
    border-radius: 3px;
    align-items: center;
    white-space: nowrap;
    -webkit-box-shadow: 2px 2px 6px 0px rgba(92, 92, 92, 0.13);
    -moz-box-shadow: 2px 2px 6px 0px rgba(92, 92, 92, 0.13);
    box-shadow: 2px 2px 6px 0px rgba(92, 92, 92, 0.13);
  }

  .item__btm-email--personal {
    display: flex;
    align-items: center;
    gap: 2%;
  }

  .item__btm-contact-link {
    white-space: nowrap;
    // button {
    //   font-size: 0.7325rem;
    //   line-height: 1.25;
    // }
    svg {
      color: #136b98;
    }
    button {
      font-size: 0.84rem;
      // line-height: 1.25;
    }
  }

  .emails__list {
    box-sizing: border-box;
    background-color: #fff;
    color: $color-heading-middle-grey;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
    .emails__email {
      // width: 98%;
      position: relative;
      width: auto;
    }
    .emails__email:not(:last-child) {
      border-bottom: 1px solid rgb(215, 215, 215);
    }
    button > svg {
      color: $primary-color;
      text-align: center;
      margin-right: 0;
      font-size: 1.1rem;
      cursor: pointer;
    }
    .emails__text-email {
      white-space: nowrap;
    }
    button,
    span,
    hr {
      z-index: 2;
    }
  }

  .emails__button {
    position: absolute;
    opacity: 0;
    box-sizing: border-box;
    display: flex;
    gap: 0.5vw;
    bottom: -25px;
    width: 270px;
    height: 25px;
    right: -1px;
    background: $primary-color-light-light;
    color: #fff;
    align-items: center;
    border-radius: 0 0 3px 3px;
    justify-content: center;
    font-weight: normal;
    font-size: 0.85rem;
    cursor: pointer;
    svg {
      font-size: 16px;
      width: 16px;
      margin-right: 0;
    }
    &:hover {
      background-color: $primary-color-light-light--hover;
    }
    &.email-modal__button--active {
      opacity: 1;
    }
  }

  .emails__email {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 65%;

    .MuiDivider-root {
      // margin-left: auto;
      margin-left: 4px;
      margin-right: 10px;
    }
  }
  .emails__status-container {
    // width: 35%;
    min-width: 120px;
    cursor: pointer;
    // .info-span {
    //   background-color: #fff !important;
    //   color: $color-heading-middle-grey !important;
    // }
  }
  .emails__status {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 7px 0.4px 7px;
    // margin: 0 4px;
    margin-right: 10px;
    height: 24px;
    color: #fff;
    border-radius: 2px;
    font-weight: normal;
    font-size: 0.76rem;
    background-color: $color-gold;
    white-space: nowrap;
    .MuiSvgIcon-root {
      margin-right: 8px;
      font-size: 1.34rem;
    }
    &.emails__status--verified {
      background-color: $color-light-green;
    }
    &.emails__status--verified--yellow {
      background-color: $color-light-gold;
    }
    &.emails__status--inactive {
      background-color: $color-light-red;
    }
    &.emails__status--notchecked {
      background-color: $color-light-grey;
    }
  }

  .emails__date {
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    margin-top: 2px;
    font-size: 0.75rem;
    margin-left: auto;
    white-space: nowrap;
  }

  .email-save-span,
  .phone-save-span {
    position: absolute;
    width: 120px;
    right: 0;
    top: -28px;
    font-size: 0.8rem;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    height: 24px;
    padding: 0 6px 0 6px;
    background-color: $primary-color-light-light;
    border-radius: 2px;
    color: #fff;
    opacity: 1;
    visibility: visible;
    z-index: 1 !important;
    transition: all 0.3s;
    &.email-save-span--persons {
      top: -38px;
      right: 30px;
    }
    &.email-save-span--legal-name {
      top: -34px;
      right: -30px;
    }
    &.email-save-span--vat {
      top: 9px;
      right: 12px;
    }
  }
  .personal-copy {
    left: 24%;
  }
  .base-email-copy {
    visibility: hidden;
  }
  .phone-save-span {
    top: -28px;
    right: 0;
  }

  .email-save-span--blink,
  .phone-save-span--blink {
    opacity: 1;
    visibility: visible;
    transition: all 0.5s;
  }
  .save-phone-button {
    padding: 6px;
    margin-left: 2px;
    margin-right: 2px;
    svg {
      margin: 0;
      font-size: 1.1rem;
      // min-width: 0 !important;
      min-width: 16px;
      min-height: 16px;
    }
  }
  .btm-tel__array {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: wrap;
    // gap: 5px;
    // flex-direction: row;
    // line-height: 25px;
    width: 100%;
    transition: all 0.3s linear;
    // span {
    //   margin-right: 1%;
    // }
    .MuiStack-root {
      margin: auto;
    }
  }
  .btm-tel__icon {
    width: 6%;
    height: 22px;
    display: flex;
    align-items: center;
  }
  .btm-tel__phone {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.94rem;
    // gap: 2%;
    // width: 39%;
  }
  .btm-tel__value {
    white-space: nowrap;
    font-size: 0.92rem;
    color: $color-heading-middle-grey;
  }
  .divider-phone {
    margin: 0 10px 0 4px;
  }
}
.company__btm-report {
  display: block;
  position: relative;
  height: 20px;
  margin-top: auto;
  margin-bottom: 10px;
  button {
    position: absolute;
    text-transform: none;
    right: -5px;
    bottom: -8px;
    font-size: 0.78rem;
    color: $primary-color-light;
    border-radius: 2px;
    height: 30px;
  }
}

.company__section-phones {
  width: auto;
  min-width: 360px;
  max-width: 450px;
  margin-top: 15px;
  svg {
    // font-size: 15px !important;
    margin-left: 2px;
  }
}
.company__section-persons {
  margin-top: 15px;
  width: 100%;
  gap: 12px;
  .persons {
    display: flex;
    flex-direction: column;
    .persons__row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      // height: 30px;
      gap: 6px;
      flex-wrap: wrap;
      margin-top: 7px;
      padding-bottom: 5px;
      margin-bottom: 7px;
      .persons__column-person {
        display: flex;
        align-items: center;
        order: 3;
        color: $color-heading-middle-grey;
        font-weight: 500;
      }
      .persons__container-h {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $color-heading-middle-grey;
        .persons__name {
          display: flex;
          align-items: center;
          margin-right: 6px;
          .MuiSvgIcon-root {
            color: $primary-color-light-light;
            font-size: 1.4rem;
            margin-right: 9px;
          }
        }
        .persons__title {
          font-size: 0.82rem;
          padding: 0 8px;
          border-radius: 2px;
          background-color: $primary-color-ten;
          color: $color-heading-light-grey;
          height: 22px;
          display: flex;
          align-items: center;
        }
        .persons__open {
          svg {
            transform: rotate(90deg);
            font-size: 0.86rem;
            transition: all linear 0.2s;
          }
        }
        .persons__open--active {
          svg {
            transform: rotate(270deg);
          }
        }
      }
      .persons__list {
        width: 100%;
        .MuiCollapse-wrapperInner {
          flex-direction: column;
          gap: 10px;
          margin-top: 5px;
        }
      }
      .persons__column-email {
        order: 5;
        margin-left: 3px;
        font-size: 0.94rem;
        position: relative;
        height: 24px;
      }
      .persons__column-phone {
        order: 6;
        margin-left: 3px;
        height: 24px;
      }
      .persons__column-linkedin {
        order: 7;
        height: 24px;
        a {
          padding: 0 10px 0 5px;
          -webkit-box-shadow: $color-shadow-distr-new !important;
          -moz-box-shadow: $color-shadow-distr-new !important;
          box-shadow: $color-shadow-distr-new !important;
          height: 26px;
        }
      }
      .persons__column-email,
      .persons__column-phone,
      .persons__column-linkedin {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 5px;
        color: $color-heading-middle-grey;
        margin-left: 20px;
        svg {
          color: $primary-color-light-light;
          width: 1.3rem;
          height: 1.3rem;
          margin-left: 3px;
          margin-right: 10px;
          transition: all linear 0.14s;
        }
      }
      .persons__column-linkedin {
        svg {
          margin-right: 0;
          margin-left: 1px;
        }
        .persons__linkedin svg {
          color: #0e76a8;
        }
      }
    }
    .persons__row:not(:last-child) {
      border-bottom: 1px solid rgb(210, 210, 210);
    }
  }
  .persons__email {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.3rem;
    height: 2.3rem;
    margin-left: 5px;
    padding: 8px;
    svg {
      margin: 0 !important;
      font-size: 0.82rem !important;
      width: 1.16rem !important;
      height: 1.16rem !important;
    }
  }
}
.btm-tel__inner-loader {
  display: flex;
  position: absolute;
  left: 0;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  align-items: center;
  gap: 3px;
  .css-1jvj4pm-MuiLinearProgress-root {
    border-radius: 8px;
    height: 6px;
  }
}

.btm-tel__inner--data {
  display: flex;
  // position: relative;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 4px;
  min-height: 34px;
  // padding-top: 4px;
  gap: 4px;
  max-height: 150px;
  height: auto;
  .MuiCollapse-root {
    width: 120%;
    transition: all linear 0.3s;
  }
  .MuiCollapse-wrapper {
    padding: 0 !important;
  }
}

.btm-tel__inner--data:not(:last-child) {
  border-bottom: 1px solid #d5d5d5;
}

.btm-tel__empty {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  margin-top: 4px;
  min-height: 36px;
  gap: 4px;
  height: auto;
  font-size: 0.9rem;
  .invalid-phone {
    margin-left: 10px;
  }
  svg {
    color: $primary-color-light-light;
  }
}
.btm-tel__empty--progress {
  position: absolute;
  width: 100%;
  height: 30px;
  top: 0;
  left: 0;
}

// .btm-tel__inner--data:first-of-type {
//   border-top: 1px solid #d5d5d5;
// }
.btm-tel__top {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.btm-tel__bottom {
  width: 100%;
  margin-top: 0;
  padding: 14px 0 10px 0;
  display: flex;
  flex-direction: row;
  gap: 5%;
  font-size: 0.9rem;
  font-weight: 500;
  color: $color-heading-middle-grey;
}
.btm-tel__bottom-left {
  border-right: 1px solid #d5d5d5;
  width: 50%;
}
.btm-tel__country {
  display: flex;
  gap: 5%;
  align-items: center;
  justify-content: flex-start;
}
.btm-tel__bottom-right {
  width: 50%;
}
.btm-tel__bottom-left,
.btm-tel__bottom-right {
  > div {
    margin-bottom: 8px;
  }
}

.btm-tel__status {
  display: flex;
  align-items: center;
  padding: 0 7px 0.4px 7px;
  margin-right: 10px;
  height: 24px;
  background-color: $color-light-green;
  border-radius: 2px;
  color: #fff;
  font-size: 0.82rem;
  font-weight: 400;
  padding-bottom: 1px;
  font-size: 0.78rem;
  &.btm-tel__status--unknown {
    background-color: rgb(151, 151, 151);
  }
  svg.MuiSvgIcon-root {
    color: #fff;
    margin-right: 6px;
    min-width: 18px;
    margin-left: 0;
    font-size: 1.14rem;
  }
}

.btm-tel__open {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-left: auto;
  .MuiButtonBase-root {
    margin-left: 14px;
    padding: 0 10px;
    width: 80px;
    border-radius: 2px !important;
    font-size: 0.76rem;
    gap: 6px;
    padding-bottom: 1px !important;
    .MuiButton-endIcon {
      justify-content: center;
      svg {
        font-size: 0.9rem;
      }
    }
  }
  // height: 20px;
  // background-color: #fff;
  // width: 20%;
}
.btm-tel__button {
  padding: 1px 5px 0 5px !important;
  display: flex;
  align-items: center;
  color: #fff;
  // justify-content: space-evenly !important;
  text-transform: none !important;
  width: 100%;
  gap: 3%;
  line-height: 1.56;
  height: 26px;
  border-radius: 3px !important;
  .MuiButton-endIcon {
    padding: 0;
    margin: 0;
    width: 20px;
    svg {
      color: #fff;
      transform: rotate(90deg);
      margin: 0 !important;
    }
  }
  &.btm-tel__button--opened {
    .MuiButton-endIcon svg {
      transform: rotate(-90deg) !important;
    }
  }
}
.btm-tel__row--date-validate {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.btm-tel__date {
  padding: 0 6px;
  background-color: $color-light-green;
  color: #fff;
  border-radius: 2px;
  font-size: 12px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btm-tel__row--intern-call {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  .btm-tel__intern-true,
  .btm-tel__intern-false {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 4px;
      color: $color-light-green;
      font-size: 18px !important;
    }
  }
  .btm-tel__intern-false {
    svg {
      color: $color-light-red;
    }
  }
}

.distributor-notes {
  margin-top: 22px;
  position: relative;
  label {
    font-size: 0.9rem;
  }
  .distributor-notes__input {
    width: 100%;
    background-color: #fff;
    textarea {
      font-size: 0.9rem;
      line-height: 1.22rem;
      padding-right: 5px;
      color: $color-heading-middle-grey;
    }
    .MuiInputBase-root:hover fieldset {
      border-color: $primary-color;
    }
  }
  .MuiInputBase-root {
    border-radius: 2px;
    padding: 14px 8px 14px 14px;
  }
}
.distributor-notes__h {
  display: flex;
  width: 100%;
  height: 30px;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.82rem;
  color: $color-heading-light-grey;
}

.distributor-notes__notification {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  position: absolute;
  height: 24px;
  width: 72px;
  line-height: 30px;
  background-color: $primary-color-light-light;
  color: #fff;
  padding: 0 6px;
  right: 100px;
  bottom: -2px;
  opacity: 0;
  transition: opacity 0.4s;
  font-size: 0.78rem !important;
  align-items: center;
  -webkit-box-shadow: 2px 2px 6px 0px rgba(92, 92, 92, 0.13);
  -moz-box-shadow: 2px 2px 6px 0px rgba(92, 92, 92, 0.13);
  box-shadow: 2px 2px 6px 0px rgba(92, 92, 92, 0.13);
}
.distributor-notes__notification--visible {
  opacity: 1;
  transition: opacity 0.5s;
}

.distributor-notes__save-btn {
  position: absolute !important;
  bottom: -2px;
  right: 0;
  // transform: translateY(-50%);
  // top: 0;
  // bottom: 0;
  // right: -50px;
  height: 24px;
  width: 72px;
  border-radius: 2px !important;
  text-transform: none !important;
  font-size: 0.78rem !important;
  align-items: center;
  padding: 0 6px !important;
  .MuiButton-startIcon {
    svg {
      font-size: 1rem !important;
    }
  }
}

.container-bottom__left {
  width: $checkbox-item + 1% + $name-item + 1% + $width-signs;
  min-width: $checkbox-item + 1% + $name-item + 1% + $width-signs;
  margin-left: 3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // justify-content: space-between;
  border-right: 1px solid rgb(230, 230, 230);
  padding: 20px 8px 20px 8px;
  box-sizing: border-box;
  min-height: 100px;
}

.container-bottom__center {
  width: $region-item + 1% + $country-item + 1% + $type-item + 1% + $size-item +
    1% + $products-item + 1%;
  border-right: 1px solid rgb(230, 230, 230);
  // margin-left: -1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
}

.container-bottom__right {
  width: auto;
  max-width: 417px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 8px 2px 10px 20px;
  box-sizing: border-box;
}

//\\\// Section of item fields BOTTOM

.content__block--sm {
  position: relative;
  padding: 0 2%;
  background-color: $color-background-block;

  border-radius: 8px;

  border: 2px solid transparent;

  -webkit-box-shadow: $color-shadow-block;
  -moz-box-shadow: $color-shadow-block;
  box-shadow: $color-shadow-block;

  transition: all 0.4s;
}

.content__block--sm--active {
  border: 2px solid $secondary-color-light;
  box-shadow: 3px 1px 17px 7px rgba(105, 159, 186, 0.19);
  -webkit-box-shadow: 3px 1px 17px 7px rgba(105, 159, 186, 0.19);
  -moz-box-shadow: 3px 1px 17px 7px rgba(105, 159, 186, 0.19);
  transition: all 0.4s;
}

.hidden-filter {
  background-color: rgba(113, 117, 149, 0.525);
  width: 100%;
  height: 100%;
  min-height: 120px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  display: none;
  opacity: 0;
  visibility: hidden;
  gap: 5%;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1rem;
  // text-transform: uppercase;
}

.hidden-filter--enabled {
  display: flex;
  opacity: 1;
  visibility: visible;
}

.filters {
  position: relative;
  padding: 20px 1.5rem 14px 1.5rem;
  margin: 10px 0 30px 0;
}

.results {
  transition: all linear 0.26s;
  padding: 0 2%;
  margin-top: 8px;
  position: relative;
}

/* Component User Info */

.user-info {
  display: flex;
  gap: 7%;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height: 64px;
  // justify-content: space-evenly;
  justify-content: flex-start;
  // padding: 0 1.5%;
  align-items: center;
  // box-sizing: border-box;
  // background-color: #fff;
  // border-radius: 6px;
  border-radius: 0;
  // -webkit-box-shadow: $color-shadow-block;
  // -moz-box-shadow: $color-shadow-block;
  // box-shadow: $color-shadow-block;
  transition: all linear 0.16s;
  .user-info__refresh {
    display: flex;
    align-items: center;
    position: absolute;
    top: -20px;
    right: 10px;
    height: 16px;
    &:hover {
      border-bottom: 1px solid rgba(70, 32, 53, 0.455);
    }
    a {
      display: flex;
      align-items: center;
      color: $color-heading-light-grey;
      font-size: 0.74rem;
      text-decoration: none;
      svg {
        width: 0.86rem;
        height: 0.86rem;
        margin-right: 4px;
      }
      &:hover {
        color: $primary-color;
      }
    }
  }

  .user-info__welcome {
    width: 28%;
    min-width: 24%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    // padding: 0 20px;
    padding-left: 10px;
    min-height: 64px;
    color: $color-heading-middle-grey;
    overflow: hidden;
  }
  .welcome__name {
    font-weight: 600;
    font-size: 0.86rem;
    margin: 4px 0 2px 0;
    color: $color-heading-middle-grey;
  }
  .welcome__heading {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    margin: 5px 0;
    font-size: 0.82rem;
  }
  .welcome__email {
    margin: 3px 0;
    font-size: 0.86rem;
  }
  .user-info__plan {
    width: 100%;
    // min-width: 80%;
    margin: 0 auto;
    svg {
      font-size: 0.9rem;
    }
  }

  .user-info__container-credits {
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 18px;
    height: 100%;
    width: auto;
    // min-width: 41%;
    // flex-wrap: wrap;
    margin-bottom: 2px;
    button {
      margin-left: 1%;
      padding: 0;
      height: 1.8rem;
      width: 1.8rem;
    }
    button svg {
      width: 1.1rem;
      height: 1.1rem;
      color: $color-light-grey;
    }
  }

  .user-info__inner {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    box-sizing: border-box;
    gap: 6px;
  }

  .user-info__heading-credits {
    display: flex;
    // height: 34px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    // width: 100%;
    // min-width: 100%;
    box-sizing: border-box;
    font-size: 0.76rem;
    color: $color-heading-light-grey;
    span {
      text-align: right;
      white-space: nowrap;
    }
  }
  .user-info__credits,
  .user-info__views {
    display: flex;
    white-space: nowrap;
    // min-width: 155px;
    position: relative;
    justify-content: center;
    align-items: center;
    .user-info__counter {
      display: flex;
      gap: 8px;
      align-items: center;
      margin: 0;
      height: 30px;
      min-height: 30px;
      font-weight: 600;
      box-sizing: border-box;
      color: $color-heading-middle-grey;
    }

    .limit-temp {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: absolute;
      width: 100%;
      height: 26px;
      bottom: -36px;
      font-size: 0.7rem;
      font-weight: 500;
    }
  }
  .user-info__credits {
    margin-right: 10px;
  }
  .user-info__text-counter {
    font-size: 0.9rem;
  }
  .user-info__download,
  .user-info__view {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    min-width: 28px;
    height: 28px;
  }
  .user-info__download {
    background-color: $color-green-icon-bg;
    svg {
      color: $color-green-icon;
      font-size: 1.36rem;
    }
  }
  .user-info__view {
    background-color: $ocean-light-color-bg;
    svg {
      color: $ocean-light-color;
      font-size: 1.32rem;
    }
  }

  .user-info__expired {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 5px;
    a {
      white-space: nowrap;
      line-height: 1.3rem;
      height: 30px;
      border-radius: 2px;
    }
  }
  p {
    margin: 0;
    font-size: 0.8rem;
    text-align: center;
    white-space: nowrap;
    color: $color-heading-light-grey;
  }
}

.user-info.user-info--discount {
  // border-radius: 6px 6px 0 0;
  border-radius: 0;
}

.discount {
  position: absolute;
  width: 96%;
  box-sizing: border-box;
  padding: 0px 8px;
  height: 26px;
  bottom: -34px;
  left: 4%;
  background-color: rgb(229, 249, 229);
  color: $color-heading-grey;
  // border-radius: 0 0 6px 6px;
  -webkit-box-shadow: $color-shadow-block-new-light;
  -moz-box-shadow: $color-shadow-block-new-light;
  box-shadow: $color-shadow-block-new-light;
  display: flex;
  align-items: center;
  justify-content: center;
  // clip-path: inset(0 -18px -18px -18px);
  .discount__text {
    font-size: 0.74rem;
    font-weight: 600;
    white-space: normal;
  }
}

/* Content */

.content {
  // margin-top: 38px;
  // width: 82%;
  // min-width: 82%;
  // max-width: 82%;
  width: calc(100% - 248px);
  min-width: calc(100% - 248px);
  max-width: calc(100% - 248px);
  position: relative;
  // padding: 0 3.5% 0 2.5%;
  box-sizing: border-box;
}

/* Heading main content */

.heading {
  margin: 0;
  padding: 0 1.5%;
  box-sizing: border-box;
  display: inline-flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  z-index: 15;
  border-left: 1px solid rgb(243 243 243);
  -webkit-box-shadow: $color-shadow-block-new !important;
  -moz-box-shadow: $color-shadow-block-new !important;
  box-shadow: $color-shadow-block-new !important;
  .heading__container {
    width: auto;
    white-space: nowrap;
    position: relative;
    padding: 0 1.5%;
  }
  .heading__additional {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-top: 3px solid $primary-color-half;
    height: 36px;
    font-size: 0.95rem;
    font-weight: bold;
    color: $color-heading-middle-grey;
  }
  h1 {
    font-size: 1.4rem;
    text-transform: uppercase;
    color: $color-heading-middle-grey;
    margin-bottom: 8px;
    margin-top: 10px;
  }
  .heading__buttons {
    display: flex;
    position: relative;
    // flex-wrap: wrap;
    flex: 0 0 auto;
    justify-content: flex-end;
    white-space: nowrap;
    box-sizing: border-box;
    // flex-wrap: nowrap;
    box-sizing: border-box;
    gap: 2.5%;
    width: auto;
    transition: all linear 0.2s;
    padding-left: 1.5%;
    border-left: 1px solid $vertical-divider;
    a {
      // padding: 0px 10px;
      border-radius: 2px;
      font-size: 0.86rem;
      height: 30px;
      text-transform: none;
      .MuiButton-startIcon {
        margin-left: 5px;
      }
    }
  }
  .button-text {
    width: 100%;
    margin-right: 5px;
  }
  .zsiq_floatmain {
    position: absolute;
    bottom: -200px !important;
  }
  .zsiq_theme1.zsiq_floatmain {
    visibility: hidden;
    bottom: -200px !important;
    display: none !important;
  }
  .zsiq_floatmain--hidden {
    top: -200px !important;
  }
  .heading__chat-toggler {
    display: flex;
    gap: 5px;
    // width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    margin-left: auto;
    margin-right: 5px;
    text-align: center;
    width: 40%;
    // padding: 0 5px;
    padding-left: 10px;
    padding-top: 8px;
    // border-radius: 10px;
    border-top: 1px solid $primary-color-light-light;
    .chat-toggler__text {
      font-size: 0.92rem;
    }
    .MuiFormControlLabel-root {
      margin: 0;
      color: $color-heading-middle-grey;
    }
    // .MuiSwitch-root {
    //   height: 30px;
    // }
  }
}

/* Under heading */
.underheading {
  display: flex;
  align-items: flex-start;
  // align-items: center;
  justify-content: flex-start;
  gap: 2%;
  margin-top: 28px;
  margin-bottom: 24px;
}

.trust-pilot {
  display: flex;
  width: 32%;
  border-radius: 3px;
  height: 50px;
  background-color: $primary-color-light;
  color: #fff;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  flex-wrap: wrap;
  height: 66px;
  box-sizing: border-box;
  -webkit-box-shadow: $color-shadow-block-new;
  -moz-box-shadow: $color-shadow-block-new;
  box-shadow: $color-shadow-block-new;
  .trust-pilot__stars {
    display: flex;
    // white-space: nowrap;
    flex-wrap: nowrap;
    width: 45%;
    svg {
      color: #fbe36a;
      font-size: 1.8rem;
    }
  }
  .trust-pilot__button {
    width: 25%;
  }
  .trust-pilot__text {
    font-size: 0.86rem;
    // line-height: 1.2rem;
    margin-top: 5px;
    width: 100%;
  }
}
/* Counter above database */

.counters {
  display: inline-flex;
  width: 33%;
  gap: 4%;
  margin: 36px 0 30px 0;
  .content__block--sm {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-evenly;
    // gap: 20px;
    // width: 50%;
    min-height: 120px;
    // }
    // .content__block--sm {
    justify-content: space-between;
    padding: 0;
    border-radius: 6px;
    border: 0 !important;
    &.content__block--sm--active {
      box-shadow: 3px 3px 10px 4px rgba(104, 41, 138, 0.19);
      -webkit-box-shadow: 3px 3px 10px 4px rgba(104, 41, 138, 0.19);
      -moz-box-shadow: 3px 3px 10px 4px rgba(104, 41, 138, 0.19);
      transition: all 0.3s;
    }
  }
}

.counters__inner {
  width: 100%;
  min-height: 170px;
  height: 170px;
}

.counters__outer-container {
  width: 100%;
  // width: calc(100% + 2px);
  // position: absolute;
  // top: -28px;
  // right: -2px;
  font-weight: normal;
  height: 30px;
  border-radius: 6px 6px 0 0;
  background: $primary-color-light;
  display: flex;
  // gap: 1%;
  // gap: 10%;
  align-items: center;
  justify-content: center;
  .counters__outer-heading {
    width: 30%;
    margin: 0 auto 0 5%;
    box-sizing: content-box;
    font-size: 0.85rem;
    color: #fff;
    // font-weight: bold;
    white-space: nowrap;
  }
  .counters__outer-item {
    display: flex;
    gap: 2%;
    box-sizing: content-box;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 5px;
    padding: 0 2%;
    height: 24px;
    font-size: 0.8rem;
    white-space: nowrap;
    svg {
      margin-right: 2px;
    }
  }

  .counters__text-span {
    display: inline-block;
    min-height: 26px;
  }

  .counters__updated-counter {
    svg {
      width: 0.8em;
      height: 0.8em;
    }
    // background-color: $ocean-light-color;
  }
}

.counters__heading {
  display: flex;
  width: 100%;
  height: 66px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 0.92rem;
  font-weight: bold;
  gap: 12px;
  // height: 46px;
  color: $color-heading-light-grey;
}
.counters__filters {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
  .counters__toggle {
    width: 40%;
    display: flex;
    justify-content: center;
  }
}
.counters__visible-buttons {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  height: 42px;
  margin-top: 4px;
  margin-bottom: -2px;
  // margin-bottom: 6px;
  .MuiBox-root {
    width: 45%;
    height: 40px;
    display: flex;
    align-items: center;
    .MuiFormControl-root {
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .MuiInputBase-root {
    height: 38px;
  }
  button {
    display: flex;
    align-items: center;
    height: 34px;
    // line-height: 38px;
    position: relative;
    // font-size: 16px;
    // text-transform: none;
    > span {
      display: flex;
      align-items: center;
      height: 100%;
      // margin-top: 2px;
    }
  }
}

.counters__hidden-buttons {
  position: absolute;
  top: -36px;
  right: 0;
  button {
    height: 24px;
  }
}

/* Updates List */

.updates-list {
  display: flex;
  flex-direction: column;
  width: 32%;
  background-color: #fff;
  padding: 0;
  border-radius: 3px;
  border: 1px solid rgb(243, 243, 243);
  -webkit-box-shadow: $color-shadow-block-new;
  -moz-box-shadow: $color-shadow-block-new;
  box-shadow: $color-shadow-block-new;
  box-sizing: border-box;
  min-height: 50px;
  max-height: 50px;
  .MuiCollapse-wrapper {
    margin-top: 12px;
  }
  .updates__container {
    position: relative;
    z-index: 15;
    margin-top: 0;
    height: 0 !important;
  }

  .updates__heading {
    display: flex;
    padding: 2px 3%;
    height: 45px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    .updates__h-icon {
      width: 5%;
      padding: 0 1%;
      font-size: 1.2rem;
      path {
        color: $primary-color-light;
      }
    }
    .updates__h-text {
      font-size: 0.86rem;
      color: $color-heading-grey;
    }
    .updates__button {
      display: flex;
      justify-content: space-between;
      padding: 0 5px;
      height: 28px;
      border-radius: 2px;
      min-width: 100px;
      .MuiSvgIcon-root {
        transform: rotate(90deg);
      }
      .updates__btn-text {
        width: 100%;
        text-align: center;
      }
      &.updates__button--opened {
        .MuiSvgIcon-root {
          transform: rotate(270deg);
        }
      }
      .MuiButton-endIcon {
        margin-left: 0;
      }
    }
  }

  .updates-list__inner {
    display: flex;
    width: 100%;
    height: auto;
    background-color: #fff;
    justify-content: space-between;
    -webkit-box-shadow: $color-shadow-block-new;
    -moz-box-shadow: $color-shadow-block-new;
    box-shadow: $color-shadow-block-new;
  }

  .updates-list__icon {
    display: flex;
    align-items: center;
    &.updates-list__icon--db {
      svg {
        width: 0.84em;
      }
    }
    &.updates-list__icon--blog {
      svg {
        width: 0.94em;
      }
    }
    svg {
      color: $primary-color-light;
    }
  }

  .updates-list__left {
    width: 4%;
    .updates-list__actions {
      border-radius: 3px 0 0 3px;
    }
    svg {
      transform: rotate(90deg);
    }
  }
  .updates-list__right {
    width: 4%;
    .updates-list__actions {
      border-radius: 0 3px 3px 0;
    }
    svg {
      transform: rotate(-90deg);
    }
  }

  .updates-list__actions.updates-list__actions--inactive {
    cursor: default;
    &:hover {
      background-color: #fff;
    }
    svg {
      color: $color-light-grey;
    }
  }

  .updates-list__center {
    width: 90%;
    padding: 16px 1% 12px 1%;
    box-sizing: border-box;
    position: relative;
  }

  .updates-list__h-container {
    display: inline-flex;
    position: relative;
    gap: 2%;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-size: 0.9rem;
    margin-bottom: 10px;

    .updates-list__date {
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      padding: 0 2%;
      background-color: $primary-color-ten;
      color: $color-heading-light-grey;
      font-size: 0.8rem;
      border-radius: 2px;
      height: 20px;
      margin-right: 5px;
    }

    .updates-list__h-text {
      font-weight: bold;
      color: $color-heading-middle-grey;
    }
  }
  // .updates-list__heading {
  //   font-size: 0.86rem;
  //   font-weight: bold;
  //   margin: 8px 0 8px 0;
  //   color: $color-heading-light-grey;
  // }

  .updates-list__text {
    font-size: 0.86rem;
    line-height: 1.25rem;
  }

  .updates-list__link {
    margin-left: 5px;
  }

  .updates-list__actions {
    // position: absolute;
    // bottom: -14px;
    // height: 16px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 24%;
    // left: 0;
    // right: 0;
    // margin-left: auto;
    // margin-right: auto;
    cursor: pointer;
    transition: all 0.22s linear;
    svg {
      color: $primary-color-dark;
    }
    &:hover {
      background-color: $primary-color-quarter;
    }
  }
  .updates-list__body {
    display: inline-block;
    margin-bottom: 12px;
    margin-top: 8px;
    opacity: 0;
    transition: all 0.24s ease-in;
    &.updates-list__center--active {
      opacity: 1;
      transition: all 0.24s ease-in;
    }
  }
}

/* Search */
.search {
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  form {
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    border-radius: 3px;
    border: 1px solid rgb(243, 243, 243);
    -webkit-box-shadow: $color-shadow-block-new !important;
    -moz-box-shadow: $color-shadow-block-new !important;
    box-shadow: $color-shadow-block-new !important;
  }

  .search__icon-search {
    width: 5%;
    padding: 0 1%;
    font-size: 1.4rem;
    path {
      color: $primary-color-light;
    }
  }

  .search__info {
    margin-top: 8px;
    font-size: 0.75rem;
    color: $color-heading-middle-grey;
  }

  .search__icon-clear {
    // width: 7%;
    margin: 0;
    padding: 2px;
    margin-right: 10px;
    svg {
      font-size: 1.2rem;
    }
  }

  .search__input-base {
    font-size: 0.88rem;
    color: $color-heading-middle-grey;
    .MuiInputBase-input {
      padding: 3px 0;
      height: 38px;
      font-size: 0.96rem;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
    -webkit-text-fill-color: $color-heading-middle-grey !important;
  }

  &:-webkit-autofill:active {
    font-family:
      "Inter",
      "Roboto",
      -apple-system,
      BlinkMacSystemFont,
      sans-serif !important;
  }

  .search__icon-start {
    // width: 8%;
    // padding: 0 1%;
    // box-sizing: border-box;
    // margin: 0 1%;
    // padding: 8px;
    // width: 28px;
    // height: 28px;
    svg {
      font-size: 1.6rem;
    }
    // path {
    //   color: $primary-color-light;
    // }
    &:hover {
      background-color: $primary-color-ten;
    }
  }
  .search__icon-settings {
    svg {
      font-size: 1.1rem;
      color: $color-grey-icon;
    }
    &:hover {
      background-color: $color-grey-icon-bg;
    }
  }
  // .search__input {
  //   width: 100%;
  //   box-sizing: border-box;
  // }
  .search__container-settings {
    width: 100%;
    .search__settings {
      width: 100%;
      // background-color: #fff;
      box-sizing: border-box;
      margin: 0;
      .MuiFormGroup-root {
        flex-direction: row;
        justify-content: space-evenly;
      }
      .MuiCheckbox-root {
        svg {
          width: 0.74em;
          height: 0.74em;
        }
      }
      .MuiTypography-root {
        color: $color-heading-middle-grey;
        font-size: 0.86rem;
      }
    }
  }
}

/* Component Filters */

$select-height: 36px;

.content__filters {
  width: 100%;
  box-sizing: border-box;
}

.filters-container {
  width: auto;
  display: flex;
  gap: 3%;
}
.filters-container--third {
  .MuiCollapse-wrapper {
    align-items: center;
  }
}

.filters__item {
  width: 32%;

  .filter-select__control--is-focused {
    .filter-select__dropdown-indicator {
      svg {
        color: $primary-color-half;
      }
    }
  }
  .filter-select__control--menu-is-open {
    .filter-select__dropdown-indicator {
      svg {
        transition: all linear 0.2s;
        transform: rotate(180deg);
        color: $primary-color-half;
      }
    }
  }
  .filter-select__control {
    border-radius: 3px;
    min-height: $select-height;
    outline: 2px solid transparent !important;
    cursor: pointer;
    .filter-select__dropdown-indicator {
      svg {
        transition: all linear 0.2s;
      }
    }
  }
  .filter-select__control:hover {
    .filter-select__dropdown-indicator {
      svg {
        color: $primary-color-half;
      }
    }
  }

  .filter-select__menu {
    border-radius: 3px;
    font-size: 0.9rem;
    top: auto;
    z-index: 15;
  }
}
.filter-select__menu {
  z-index: 15 !important;
}
.filters__top {
  > .filters__item {
    width: 23%;
  }
}
.filters__bottom {
  margin-top: 40px;
  > .filters__item {
    width: 23%;
  }
}
.filters__third-inner {
  // margin-top: 40px;
  > .filters__item {
    width: 23%;
  }
}
.filter-select__multi-value {
  font-size: 0.98rem;
}
.filters__item--active {
  transition: all 0.4s;
  .filter-select__control {
    outline: $color-outline-select solid 1px !important;
    transition: all 0.4s;
  }
}
// Filter third line
.filters__third-outer {
  margin-top: 24px;
  margin-bottom: 12px;
  .third-outer__heading {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: rgb(102, 102, 102);
    margin-bottom: 5px;
    .MuiFormControlLabel-root {
      height: 32px;
    }
  }
  label {
    margin-left: 8px;
    .MuiTypography-root {
      font-size: 0.92rem;
    }
  }
  .inline-icon {
    height: 32px;
    svg {
      border-radius: 4px;
      padding: 0 2px;
      font-size: 1.8rem;
      color: $color-light-green;
    }
  }
}

.filters__third-inner-collapse {
  .filter-select__control {
    margin-top: 7px;
  }
}

.filters.content__block {
  padding-top: 46px;
  border-radius: 3px;
  border: 1px solid rgb(243, 243, 243);
  -webkit-box-shadow: $color-shadow-block-new-large !important;
  -moz-box-shadow: $color-shadow-block-new-large !important;
  box-shadow: $color-shadow-block-new-large !important;
}

.filter-update:before,
.filter-region:before,
.filter-country:before,
.filter-province:before,
.filter-size:before,
.filter-product:before,
.filter-type:before,
.filter-imported-countries:before {
  content: "";
  width: auto;
  height: 20px;
  position: absolute;
  top: -24px;
  left: 8px;
  font-size: 0.8rem;
  font-weight: 600;
  color: $color-heading-light-grey;
}

.filter-update {
  position: relative;
  .MuiSlider-thumb {
    width: 16px;
    height: 16px;
  }
  .MuiSlider-valueLabelOpen {
    background-color: $primary-color-light-light;
  }
  .MuiSlider-root {
    padding: 16px 0;
    .MuiSlider-markLabel {
      top: 38px;
      font-size: 0.78rem;
      color: $color-heading-light-grey;
    }
  }
  .MuiSlider-dragging {
    color: $primary-color !important;
  }
  .filter-update__slider {
    border: 1px solid #c4c4c4;
    border-radius: 3px;
  }
}
.filter-update.filters__item--active {
  .MuiSlider-root {
    border: 1px solid #c4c4c4;
    outline: #b98db7 solid 1px !important;
    transition: all 0.4s;
    color: $primary-color-light;
  }
}

.filter-update:before {
  content: "Update Date";
}

.filter-region:before {
  content: "Region";
}

.filter-country:before {
  content: "Country";
}
.country-counter {
  font-size: 12px;
  margin-left: 5px;
}

.filter-province:before {
  content: "Province";
}

.filter-size:before {
  content: "Size";
}

.filter-type:before {
  content: "Type";
}

.filter-product:before {
  content: "Distributed Products";
}
.filter-product {
  width: 100%;
}
.filter-type {
  width: 100%;
}
.filter-size {
  width: 100%;
}

.filters__under-type {
  position: absolute;
  left: 0;
  width: 98%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1%;
  color: rgb(120, 120, 120);
  // margin-top: 5px;
  height: 38px;
  .MuiTypography-root {
    font-size: 0.9rem;
  }
}

.under-type__span {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.8rem;
  svg {
    border-radius: 4px;
    padding: 0 2px;
    font-size: 1.7rem;
    color: $color-light-green;
  }
}

// Third line Filters

.filter-year:before,
.filter-employees:before,
.filter-prodq:before,
.filter-tier:before {
  margin-left: 8px;
  font-size: 0.8rem;
  font-weight: 600;
  color: $color-heading-light-grey;
  // margin-bottom: 6px;
}
.filter-year:before {
  content: "Foundation (~35% of companies)";
}
.filter-employees:before {
  content: "Employees (~35% of companies)";
}
.filter-prodq:before {
  content: "Quantity of Wines (~35% of companies)";
}
.filter-tier:before {
  content: "Data Сompleteness";
}

.item__type-span {
  position: absolute;
  display: flex;
  top: 0;
  left: $importer-left;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  background: $color-light-green;
  width: 6%;
  min-width: 90px;
  border-radius: 0 0 4px 4px;
  color: #fff;
  font-size: 0.7rem;
}

.item__email-span {
  display: flex;
  top: 0;
  width: 100%;
  height: $height-top-item-block;
  box-sizing: border-box;
  left: $verified-left - 1%;
  align-items: center;
  justify-content: flex-start;
  padding: 0 4% 0 6%;
  border-radius: 2px;
  cursor: pointer;

  box-sizing: border-box;
  color: #fff;
  font-size: 0.8rem;
  opacity: 0;
  transition: all linear 0.3s;
  span {
    width: 80%;
  }
  .email-span__icon {
    width: 18%;
    min-width: 18%;
    max-width: 18%;
  }
  .email-span__text {
    white-space: nowrap;
    width: 60%;
    min-width: 60%;
    max-width: 60%;
    margin-left: 5px;
    font-size: $fontsz-top-item;
  }
  .email-span__date {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 35%;
    min-width: 35%;
    max-width: 35%;
    height: auto;
    white-space: nowrap;
    margin-right: 5px;
    span {
      font-size: 0.72rem;
      color: #fff;
    }
  }
  svg {
    width: 9%;
    height: 0.9em;
  }
  &.item__email-span--noemail {
    background: $color-light-grey;
    opacity: 1;
    span {
      width: auto;
    }
  }
  &.item__email-span--external {
    background: $ocean-light-color;
    opacity: 1;
    span {
      width: auto;
    }
  }
  &.item__email-span--withnotes {
    background: $color-light-gold;
    opacity: 1;
  }
  &.item__email-span--invalid {
    background: $color-light-red;
    opacity: 1;
  }
  &.item__email-span--verified {
    background: $color-light-green;
    opacity: 1;
  }
}

.email-status-helper {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 17%;
  height: 80px;
  padding: 0 10px;
  background-color: #fff;
  color: red;
  top: -80px;
  left: $verified-left - 2%;
  border-radius: 5px 5px 0 0;
  color: #fff;
  font-size: 0.9rem;
  border-bottom: 1px solid #fff;
  &.email-status-helper--yellow {
    background: $color-light-gold;
  }
  &.email-status-helper--red {
    background: $color-light-red;
  }
}

.filter-imported-countries {
  width: 100%;
}

.form-control--strict {
  position: absolute;
  bottom: -36px;
  left: 20px;
  width: 100%;
  .MuiButtonBase-root {
    font-size: 0.8rem;
    color: $color-heading-middle-grey;
    width: 30px;
    height: 30px;
    padding: 0;
    input {
      width: 34px;
      height: 34px;
    }
  }
  .MuiTypography-root {
    font-size: 0.8rem;
  }
  .MuiCheckbox-root {
    svg {
      width: 0.74em;
      height: 0.74em;
      padding: 0;
      color: $color-heading-light-grey;
    }
  }
  .Mui-checked {
    svg {
      color: $primary-color;
    }
  }
}

.form-control--inactive {
  .MuiCheckbox-root {
    svg {
      width: 0.84em;
      height: 0.84em;
      color: $color-inactive;
    }
  }
}

.filter-imported-countries:before {
  content: "Importing from";
}

// Email modal statuses
.modal-window-content {
  position: fixed;
  background-color: #fff;
  color: $color-heading-grey;
  left: 50%;
  top: 38%;
  // height: 77%;
  height: auto;
  width: 58vw;
  border-radius: 4px;
  padding: 4vh 2vw;
  z-index: 11;
  transform: translate(-48%, -35%);
  border: 1px solid rgba(143, 143, 143, 0.571);

  h3 {
    font-size: 1.15rem;
    margin: 5px 0;
    color: $color-heading-middle-grey;
  }
  h4 {
    font-size: 0.95rem;
    margin: 10px 0 5px 0;
    color: $color-heading-middle-grey;
  }
  .modal-window-content__content {
    display: inline-block;
    margin: 5px 0;
  }
  .modal-window-content__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 5px 0 5px 0;
    padding: 0;
    li {
      width: 100%;
      display: flex;
      position: relative;
      margin: 6px 0;
      gap: 3%;
    }
  }

  .modal-window-content__close {
    position: absolute;
    top: -3.5em;
    right: 0;
    button {
      background-color: #ffffff61;
      color: $color-heading-light-grey;
      transition: all linear 0.3s;
      &:hover {
        background-color: #ffffffa4;
        transform: rotate(180deg);
      }
    }
    svg {
      width: 1.2em;
      height: 1.2em;
    }
  }
}
.modal-window-content__status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: 0.5em;
  left: 0;
  width: 22%;
  min-width: 22%;
  height: 32px;
  padding: 0;
  color: #fff;
  border-radius: 2px;
  font-size: 0.8rem;
  padding: 0 0.7em 0 1em;
  margin-top: 3px;
  &.modal-window-content__status--green {
    background-color: $color-light-green;
  }
  &.modal-window-content__status--red {
    background-color: $color-light-red;
  }
  &.modal-window-content__status--grey {
    background-color: $color-light-grey;
  }
  &.modal-window-content__status--yellow {
    background-color: $color-light-gold;
  }
  &.modal-window-content__status--ocean {
    background-color: $ocean-light-color;
  }
}

.modal-window-content__desc {
  width: 72%;
  font-size: 0.9em;
  display: flex;
  align-items: center;
}

/* Pagination */

.modal-summary__modal {
  width: 480px !important;
  min-height: 200px;
  border: 1px solid $primary-color !important;
  border-radius: 4px;
  padding: 24px 30px 24px 30px;
  .modal-summary__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-heading-grey;
    h2 {
      font-size: 1.2rem;
    }
    .modal-summary__text-icon {
      display: flex;
      align-items: center;
      gap: 12px;
      svg {
        color: $primary-color;
        font-size: 1.4rem;
      }
    }
  }

  .modal-summary__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    > span {
      font-size: 1rem;
      margin: 5px 0;
    }
    .modal-summary__buttons {
      display: flex;
      justify-content: space-evenly;
      gap: 5%;
      margin-top: 24px;
      button,
      a {
        border-radius: 2px;
        text-transform: none;
      }
    }
  }

  .modal-summary__notify-not-download {
    line-height: 1.8;
  }

  .modal-summary__close svg {
    font-size: 1.7rem;
  }

  .modal-summary__format-h {
    font-weight: bold;
    color: $color-heading-middle-grey;
    margin: 15px 0 10px 0 !important;
  }
  .modal-summary__checkboxes {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 5px;
    label {
      width: 48%;
      margin: 0;
    }
    .MuiTypography-root {
      font-size: 0.98rem;
    }
  }
}

.pagination {
  display: inline-flex;
  width: 96%;
  box-sizing: border-box;
  gap: 5%;
  justify-content: space-between;
  align-items: center;
  margin: 14px 0 20px 0;
  border-bottom: 1px solid rgb(217, 217, 217);
  padding-bottom: 10px;
  margin: 0 2% 10px 2%;
}

.distributor__actions {
  position: relative;
  width: 70%;
  > nav {
    // width: 280px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
}
// .pagination__buttons {
//   display: flex;
//   // gap: 20%;
//   justify-content: space-between;
//   width: auto;
//   position: relative;
//   button {
//     max-height: 34px;
//     // font-size: 0.8rem;
//   }
// }

.pagination__total-results {
  position: relative;
  width: 100%;
}

.multibar-collapsed {
  // width: 220px;
  width: 100%;
  position: absolute !important;
  right: 220px;
  > .MuiCollapse-vertical {
    padding-top: 10px;
  }
}
.folder-multi-actions__remove-btn {
  right: 0;
}
.folder-multi-actions__add-btn {
  left: 0;
}
.folder-action-btn {
  padding: 2px 10px !important;
  height: 40px;
  &:hover {
    background-color: $text-button-hover;
  }
}
.pagination__actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  position: relative;
  width: 100%;
  .actions__opener {
    background-color: transparent;
    padding-left: 0;
  }
  > nav {
    padding-bottom: 0;
  }
  .actions__collapsed {
    position: absolute;
    width: 280px;
    z-index: 10;
    background-color: #fff;
    top: 56px;
    border: 1px solid rgba(141, 82, 137, 0.25);
    border-radius: 4px;
  }
  .actions__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    color: $color-heading-light-grey;
    padding: 20px 10px;
  }
}

.pagination__container-buttons {
  width: 60%;
  display: flex;
  gap: 5%;
  justify-content: space-between;
  align-items: center;
}

// Used in actions downloads/add to folder
.inactive-action-button {
  background-color: #ededed !important;
}
.inactive-action-title {
  color: $color-inactive !important;
}

.pagination__pagination {
  display: flex;
  gap: 2rem;
  width: 60%;
  justify-content: flex-end;
  padding-right: 5px;
  .MuiPaginationItem-root {
    font-size: 0.8rem;
    width: 30px;
    height: 32px;
    padding-top: 2px;
  }
}

.number-pages {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  .filter-select__indicator {
    padding: 4px;
  }
  .filter-select__control {
    min-height: 30px;
    font-size: 0.84rem;
    border-radius: 3px;
  }
  .filter-select__menu {
    border-radius: 3px;
    font-size: 0.84rem;
  }

  .filter-select__dropdown-indicator:hover {
    svg {
      color: $primary-color-half;
    }
  }
  .filter-select__control--is-focused {
    .filter-select__dropdown-indicator {
      svg {
        color: $primary-color-half;
      }
    }
  }
  .filter-select__control--menu-is-open {
    .filter-select__dropdown-indicator {
      svg {
        transition: all linear 0.2s;
        transform: rotate(180deg);
        color: $primary-color-half;
      }
    }
    .filter-select__dropdown-indicator:hover {
      svg {
        color: $primary-color !important;
      }
    }
  }
  // height: $select-height;
  .filter-select__control {
    border-radius: 3px;
    cursor: pointer;
    .filter-select__dropdown-indicator {
      svg {
        transition: all linear 0.2s;
      }
    }
  }
  .filter-select__control:hover {
    .filter-select__dropdown-indicator {
      svg {
        color: $primary-color-half;
      }
    }
  }
}

.pagination__block {
  position: relative;
  padding: 0 14px;
  margin-left: auto;
  width: 200px;
  height: 37px;
  border-radius: 3px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.84rem;
  font-weight: 600;
  color: $color-heading-light-grey;

  -webkit-box-shadow: $color-shadow-block-new;
  -moz-box-shadow: $color-shadow-block-new;
  box-shadow: $color-shadow-block-new;
  border: 1px solid $color-border-block-new;

  box-sizing: border-box;
  z-index: 2;
}

.pagination__clear {
  display: flex;
  align-self: center;
  justify-content: center;
  position: absolute;
  width: 140px;
  height: 30px;
  left: 0;
  bottom: 0;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
  visibility: hidden;
  bottom: 3px;
  border-radius: 3px;
  z-index: 1;
  transition: all 0.3s;
  button {
    border-radius: 2px;
    -webkit-box-shadow: $color-shadow-block-new-thin;
    -moz-box-shadow: $color-shadow-block-new-thin;
    box-shadow: $color-shadow-block-new-thin;
    text-transform: none;
    font-size: 0.84rem;
    font-weight: 500;
    &:hover {
      -webkit-box-shadow: $color-shadow-block-new--hover;
      -moz-box-shadow: $color-shadow-block-new--hover;
      box-shadow: $color-shadow-block-new--hover;
    }
  }
}

.pagination__clear--active {
  opacity: 1;
  visibility: visible;
  left: 220px;
}

.pagination__button-clear {
  width: 250px;
  box-sizing: border-box;
  color: #fff !important;
  cursor: pointer;
  z-index: 99;
}

.pagination__multibar {
  position: absolute;
  height: 60px;
  top: 15px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: $primary-color;
  color: #fff;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 0 30px;
}

.pagination__container-multibar {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  height: 0;
  left: 0;
  top: 0;
  z-index: 14;
}

// Menu Content
.content-menu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 0 2%;
  margin: 15px 0 0 0;

  > div {
    max-height: 48px;
  }
  .content-menu__checkbox {
    width: 48px;
    min-width: 48px;
    margin-left: 0.8%;
    box-sizing: border-box;
    .MuiButtonBase-root {
      input {
        padding: 0;
      }
      svg {
        font-size: 1.9rem;
      }
    }
  }

  .content-menu__counter-res {
    font-size: 0.95rem;
    color: $color-heading-grey;
    font-weight: 500;
  }

  .content-menu__button {
    font-size: 0.86rem;
    border-radius: 3px;
    -webkit-box-shadow: $color-shadow-block-new;
    -moz-box-shadow: $color-shadow-block-new;
    box-shadow: $color-shadow-block-new;
    height: 32px;
    max-height: 32px;
    text-transform: none;
    &:hover {
      -webkit-box-shadow: $color-shadow-block-new--hover;
      -moz-box-shadow: $color-shadow-block-new--hover;
      box-shadow: $color-shadow-block-new--hover;
    }
  }
  .content-menu__input-button {
    border-radius: 2px;
    text-transform: none;
    height: 24px;
  }
  input {
    padding: 0 15px 0 15px;
    font-size: 0.94rem;
    margin-left: 8px;
  }
  label {
    line-height: 0.64rem;
    padding: 0 0 15px 5px;
    font-size: 0.92rem;
  }
  .content-menu__close {
    position: absolute;
    top: -36px;
    right: -26px;
    background-color: #fff;
    border: 1px solid $primary-light-border;
    &:hover {
      background-color: #faf6fa;
    }
  }
  .content-menu__save {
    position: relative;
    margin-left: auto;
  }

  .content-menu__save-box {
    position: absolute;
    background-color: #fff;
    width: 250%;
    left: 0;
    bottom: 52px;
    z-index: 15;
    height: 40px;

    .MuiFormControl-root {
      height: 40px;
      max-height: 40px;
    }
    .MuiInputBase-root {
      height: 40px;
      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: #cba3c9;
        }
      }
    }
  }
  .content-menu__input-save {
    border-radius: 2px;
    border-right: none;
    width: 100%;
    z-index: 15;
    .MuiInputBase-input {
      width: 60%;
    }
    .MuiOutlinedInput-root:hover fieldset {
      border-color: $primary-color !important;
    }
  }
  .content-menu__input-save--saved {
    opacity: 0;
  }
}

.folder-multi-actions {
  display: flex;
  position: relative;
  gap: 20px;
  .folder-action-btn {
    background-color: #fff !important;
    border: 1px solid #fbe8fa;
  }
  .folder-action-btn:hover {
    background-color: #eeeeee !important;
  }
}

// Footer pagination

.content-bottom {
  display: flex;
  justify-content: center;
  margin: 60px 0 150px 0;
  .pagination__pagination {
    margin-right: 0;
    justify-content: center;
  }
}

.content__block .distributor__button {
  font-size: 0.86rem;
  border-radius: 3px;
  -webkit-box-shadow: $color-shadow-distr;
  -moz-box-shadow: $color-shadow-distr;
  box-shadow: $color-shadow-distr;
  height: 32px;
  max-height: 32px;
  text-transform: none;
  &:hover {
    -webkit-box-shadow: $color-shadow-block-new--hover;
    -moz-box-shadow: $color-shadow-block-new--hover;
    box-shadow: $color-shadow-block-new--hover;
  }
}

//\\//\\// LOADER //\\//\\//

.loader__container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 90px 0 110px 0;

  .MuiCircularProgress-root {
    width: 240px !important;
    height: 240px !important;
  }

  span svg circle {
    stroke-width: 0.8;
  }
}

// FEEDBACK
.feedback {
  display: flex;
  margin-top: 16px;
  gap: 10px;
  // position: absolute;
  // bottom: 20px;
  // bottom: -200px;
  transition: all 0.3s linear;
  transition-delay: 150ms;
  // bottom: 30px;
  // left: 10px;
  width: 80%;
  // height: 90px;
  // background-color: #fff;
  background-color: #d8e8f7;
  // border: 1px solid $secondary-color;
  border-radius: 4px;
  padding: 8px 10px;
  z-index: 20;
  .feedback__container {
    // padding-left: 32%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    // flex-direction: column;
    gap: 20px;
  }
  .feedback__close {
    position: absolute;
    right: 1px;
    top: -45px;
  }
  .feedback__image {
    position: absolute;
    left: 12px;
    bottom: 3px;
  }
  .feedback__text {
    text-align: center;
    font-size: 0.96rem;
    line-height: 15px;
    color: $color-heading-grey;
  }
  // .feedback__heading {
  //   font-weight: bold;
  //   color: $color-heading-middle-grey;
  //   margin-bottom: 8px;
  // }
  &.feedback--active {
    // bottom: 30px;
    // transition: all 0.3s linear;
    // transition-delay: 150ms;
  }
}

.feedbackform {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  position: fixed;
  transition: all 0.3s linear;
  transition-delay: 100ms;
  // right: -2000px;
  right: -450px;
  top: 200px;
  // transform: translate(-50%, -50%);
  // width: 27%;
  width: 280px;
  height: auto;
  background-color: #fff;
  border: 1px solid $secondary-color;
  border-radius: 8px;
  padding: 10px 20px;
  z-index: 20;
  visibility: hidden;
  .feedbackform__close {
    position: absolute;
    right: 0;
    top: -40px;
  }

  .feedbackform__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    margin: 12px 0 4px 0;
    font-weight: bold;
    color: $color-heading-middle-grey;
  }

  .feedbackform__subheading {
    font-size: 14px;
    font-weight: bold;
    color: $color-heading-middle-grey;
  }

  .feedbackform__picker {
    display: flex;
    position: relative;
    width: 100%;
    margin-left: 0;
    justify-content: space-between;
    > .MuiButtonBase-root {
      padding: 5px;
    }
  }

  .feedbackform__picker--price {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .feedbackform__price {
    padding: 4px 8px;
    border: 1px solid $secondary-color;
    border-radius: 6px;
    color: $color-heading-middle-grey;
    cursor: pointer;
    transition: all 0.2s linear;
    &:hover {
      background-color: $primary-color-ten;
      color: $primary-color;
      border: 1px solid $primary-color;
    }
  }

  .rating-1 {
    .MuiSvgIcon-root {
      color: rgb(231, 77, 42);
    }
  }
  .rating-2 {
    .MuiSvgIcon-root {
      color: rgb(215, 150, 70);
    }
  }
  .rating-3 {
    .MuiSvgIcon-root {
      color: rgb(179, 175, 72);
    }
  }
  .rating-4 {
    .MuiSvgIcon-root {
      color: rgb(157, 207, 50);
    }
  }
  .rating-5 {
    .MuiSvgIcon-root {
      color: rgb(70, 185, 62);
    }
  }

  .rating-active {
    outline: 1px solid $primary-color-quarter;
    background-color: $primary-color-ten;
  }

  .feedbackform__question {
    position: relative;
    // .feedbackform__input {
    //   font-size: 0.5rem;
    // }
  }

  .feedbackform__input {
    width: 100%;
    margin-top: 15px;
  }

  .button-submit-feedback {
    margin-bottom: 15px;
  }

  .feedbackform__alarm {
    position: absolute;
    right: -7%;
    display: flex;
    height: 100%;
    align-items: center;
    &.feedbackform__alarm--text {
      top: 0;
    }
  }

  &.feedbackform--active {
    // top: 400px;
    right: 50px;
    visibility: visible;
    // left: 50%;
    // transform: translate(-50%, 50%);
    transition: all 0.2s linear;
    transition-delay: 100ms;
  }

  .feedbackform__thanks {
    width: 100%;
    height: 99%;
    z-index: 20;
    background: rgb(255, 255, 255);
    position: absolute;
    top: 0;
    left: 500%;
    border-radius: 6px;
    transition: all 0.5s linear;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      text-align: center;
      font-size: 18px;
      color: $color-heading-grey;
    }
  }
  .feedbackform__thanks--active {
    left: 0;
  }
}

.feedback-header {
  position: absolute;
  // top: 15px;
  top: -500px;
  right: -100%;
  // width: 380px;
  width: 78%;
  transition: all 0.4s linear;
  opacity: 0;
  visibility: hidden;
  .feedback-header__inner {
    display: flex;
    justify-content: space-between;
    // gap: 6px;
  }
  .feedback-header__image {
    max-width: 30%;
  }
  .feedback-header__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    max-width: 65%;
    white-space: normal;
    span {
      text-align: center;
      line-height: 21px;
      margin-bottom: 5px;
      font-size: 0.95rem;
    }
  }
  &.feedback-header--active {
    opacity: 1;
    visibility: visible;
    top: 6px;
    .feedback-header__image {
      img {
        width: 88px;
        height: auto;
      }
    }
    .feedback-button-header {
      font-size: 0.75rem;
      line-height: 1.6;
    }
  }
}

// Cheat Banner
.cheat-banner {
  position: absolute;
  width: 500px;
  height: 300px;
  border: 1px solid $primary-color-light;
  background-color: $primary-color-light;
  color: #fff;
  font-size: 1rem;
  top: 200px;
  left: 400px;
  border-radius: 6px;
  padding: 15px 35px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
  line-height: 28px;
  h2 {
    font-size: 1, 6rem;
    line-height: 36px;
  }
}

.wide-loader-item {
  box-sizing: border-box;
  width: 90%;
  margin-right: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  &.wide-loader-item--full {
    width: 97%;
    margin-right: 3%;
    height: auto;
  }
  &.wide-loader-item--full--block {
    width: 97%;
    margin-right: 3%;
    margin-top: 15px;
    height: auto;
    min-height: 120px;
    .MuiStack-root {
      height: 100%;
      .MuiLinearProgress-root {
        height: 100%;
      }
    }
  }
  &.wide-loader-item--double {
    width: 97%;
    margin-right: 3%;
    height: auto;
    margin-top: 30px !important;
    height: 90px;
    .MuiStack-root {
      height: 100%;
      .MuiLinearProgress-root {
        height: 100%;
      }
    }
  }
  margin-top: 10px;
  &:not(:first-child) {
    margin-top: 25px;
  }
}

// New company
.item__container-bottom {
  font-size: 0.94rem;

  .company__updated {
    position: absolute;
    right: 15px;
    top: -9px;
    font-size: 0.76rem;
    height: 22px;
    width: 124px;

    .company__container-date {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      width: 100%;
      height: 100%;
      padding: 0 6px;
      background-color: $primary-color-ten;
      color: $color-heading-light-grey;
    }
  }
  .company__section {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    min-height: 20px;
  }
  // Name
  .company__section-name {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    min-height: 20px;
    position: relative;
  }
  .company__trade-name {
    margin-top: 2px;
  }
  .company__trade-name,
  .company__legal-name {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
  }
  .company__legal-name {
    max-width: calc(100% - 34px);
  }

  .company__text-content {
    color: $color-heading-middle-grey;
  }
  .company__heading {
    color: $color-heading-middle-grey;
    font-size: 0.8rem;
  }

  // Address
  .company__section-address {
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-height: 20px;
  }
  .company__address {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 18px;
    &.address-legal {
      margin-top: 8px;
    }
  }
  .company__address-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
    svg {
      margin-right: 10px;
      color: $primary-color-light-light;
      font-size: 1.24rem;
    }
  }
  .company__address-branches {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 3px;
    max-width: 96%;
    margin-top: 3px;
    .address-branches__h {
      color: $color-heading-middle-grey;
      font-size: 0.8rem;
      margin-right: 2px;
    }
    .address-branches__span {
      color: $color-heading-middle-grey;
      font-size: 0.8rem;
      margin-right: 1px;
    }
  }
  // ID and VAT
  .company__section-id {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 60px;
    position: relative;
    .company__id,
    .company__vat {
      margin-top: 18px;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    .company__id-content,
    .company__vat-content {
      display: flex;
      gap: 5px;
      position: relative;
      svg {
        margin-right: 4px;
        color: $primary-color-light-light;
        font-size: 1.2rem;
      }
      .MuiDivider-root {
        margin: 0 5px 0 4px;
        height: 20px;
      }
      .MuiButtonBase-root {
        margin-left: -3px;
        position: absolute;
        right: -32px;
        bottom: -6px;
        svg {
          font-size: 1.1rem;
          margin-right: 0;
        }
      }
    }
  }

  // Year and Employees
  .company__section-ye {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 50px;
    .company__year,
    .company__employees,
    .company__product-qty {
      margin-top: 18px;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    .company__year-content,
    .company__employees-content,
    .company__product-qty-content {
      display: flex;
      gap: 5px;
      svg {
        margin-right: 3px;
        color: $primary-color-light-light;
        font-size: 1.2rem;
      }
    }
    .company__product-qty-content {
      svg {
        margin-right: 2px;
        margin-top: 2px;
        color: $primary-color-light-light;
        font-size: 0.94rem;
        transform: rotate(-45deg);
      }
    }
  }
  // Financials
  .MuiList-root .MuiButtonBase-root {
    font-size: 0.8rem !important;
  }
  .company__section-finance {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    // gap: 15px;
    margin-top: 20px;
    padding-top: 15px;
    flex-wrap: wrap;
    border-top: 1px solid rgb(230, 230, 230);
    .tooltip {
      padding: 3px;
      margin-left: 3px;
      margin-bottom: 1px;
      svg {
        width: 1.1rem;
        height: 1.1rem;
        color: $color-heading-super-light-grey;
      }
    }
    .company__sales,
    .company__profit,
    .company__add-finance {
      display: flex;
      flex-direction: column;
      gap: 3px;
      width: 30%;
      margin-right: 3%;
    }
    .company__fin-year {
      width: 100%;
      margin-left: auto;
      margin-right: 15px;
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: flex-start;
      margin-bottom: 15px;
      .MuiFormControl-root {
        margin-right: 20px;
      }
      .MuiOutlinedInput-root {
        border-radius: 4px;

        &:hover fieldset {
          border-color: rgb(
            170,
            132,
            168
          ) !important; // Цвет рамки при наведении
          border-width: 1px !important; // Толщина рамки при наведении
        }

        &.Mui-focused fieldset {
          border-color: rgb(150, 100, 130) !important; // Цвет рамки при фокусе
          border-width: 1px !important; // Толщина рамки при фокусе
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.23); // Цвет рамки по умолчанию
        border-width: 1px; // Толщина рамки по умолчанию
      }
    }
    .company__sales-content,
    .company__profit-content,
    .company__add-finance-content {
      display: flex;
      gap: 5px;
      align-items: flex-end;
      .fin-number {
        font-size: 1rem;
        font-weight: 700;
        color: $color-heading-light-grey;
        .fin-number-zero {
          font-size: 0.92rem;
          font-weight: 500;
        }
      }
      .fin-currency {
        font-size: 0.8rem;
        font-weight: 500;
        color: $color-heading-light-grey;
        padding-bottom: 2px;
      }
      svg {
        margin-right: 4px;
        color: $primary-color-light-light;
        font-size: 1.3rem;
      }
    }
    .company__fin-year-content {
      display: flex;
      gap: 5px;
      min-width: 92px;
      svg {
        margin-right: 3px;
        color: $primary-color-light-light;
        font-size: 1.2rem;
      }
    }
  }

  // Product qty
  .company__product-qty-content {
    svg {
      margin-right: 2px;
      margin-top: 2px;
      color: $primary-color-light-light;
      font-size: 0.94rem;
      transform: rotate(-45deg);
    }
  }

  // Types of single company
  .company__section-type {
    display: flex;
    min-width: 90%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 7px;
    margin-top: 15px;
  }
  .company__btm-type {
    display: flex;
    width: 100%;
    gap: 5px;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    > svg {
      width: 22px;
      min-width: 22px;
      height: 22px;
      color: $primary-color-light-light;
      margin-right: 5px;
    }
    .btm-types__item {
      display: flex;
      gap: 6px;
      align-items: center;
      justify-content: center;
      // background-color: rgb(237, 233, 240);
      background-color: $bg-top-item-section;
      border-radius: 2px;
      height: 26px;
      padding: 0 7px;
      margin-right: 4px;
      font-size: 0.83rem;
      -webkit-box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.12);
      -moz-box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.12);
      box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.12);
      svg {
        color: $primary-color;
        font-size: 1rem;
      }
    }
  }

  // List of countries imported/distributed
  .company__section-prodcountry {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    margin-top: 18px;
  }
  .company__btm-prod-country {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    // line-height: 30px;
    color: $color-heading-middle-grey;
    align-items: center;
    > svg {
      // font-size: 24px;
      font-size: 1.34rem;
      margin-left: -2px;
      color: $primary-color-light-light;
      margin-right: 5px;
    }
    .company__flag-country {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 8px;
      color: $color-heading-middle-grey;
      img {
        margin-right: 6px;
        opacity: 0.8;
      }
    }
  }
  .sm-in-open {
    height: 18px;
    display: flex;
    align-items: center;
  }
  // Product types in Company profile
  .company__section-btm-products {
    display: flex;
    min-width: 90%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 3px;
    margin-top: 15px;
  }
  .company__btm-products {
    display: flex;
    width: 100%;
    gap: 5px;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    > svg {
      width: 18px;
      min-width: 18px;
      height: 18px;
      color: $primary-color-light-light;
      margin-right: 5px;
    }
    .btm-products__item {
      display: flex;
      gap: 3px;
      align-items: center;
      justify-content: center;
      // background-color: rgb(237, 233, 240);
      background-color: $bg-top-item-section;
      border-radius: 3px;
      height: 26px;
      padding: 0 8px;
      margin-right: 2%;
      margin-bottom: 5px;
      margin-top: 5px;
      -webkit-box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.12);
      -moz-box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.12);
      box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.12);
      span {
        font-size: 0.85rem;
      }
      &.btm-products__item--wine {
        svg {
          color: $primary-color;
        }
      }
      &.btm-products__item--spirits {
        svg {
          color: rgb(192, 105, 43);
          // font-size: 18px;
        }
      }
      &.btm-products__item--beer {
        svg {
          color: rgb(212, 185, 66);
          font-size: 18px;
        }
      }
      &.btm-products__item--cider {
        svg {
          transform: rotate(-45deg);
          color: rgb(55, 127, 83);
          font-size: 16px;
          margin-top: 2px;
        }
      }
      &.btm-products__item--oil {
        svg {
          // transform: rotate(-45deg);
          color: rgb(152, 156, 33);
          font-size: 18px;
          // margin-top: 2px;
        }
      }
      &.btm-products__item--bio {
        svg {
          // transform: rotate(-45deg);
          color: rgb(84, 156, 33);
          font-size: 18px;
          // margin-top: 2px;
        }
      }
      &.btm-products__item--bio-line {
        // flex-basis: 50%;
        // margin-top: 10px;
        svg {
          // transform: rotate(-45deg);
          color: rgb(84, 156, 33);
          font-size: 18px;
          margin-right: 5px;
          margin-left: 2px;
        }
      }
    }
  }

  .company__name-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .MuiDivider-root {
      margin: 0 6px 0 9px;
      height: 20px;
    }
    .MuiButtonBase-root {
      margin-left: -3px;
      position: absolute;
      right: -34px;
      bottom: -6px;
      svg {
        font-size: 1.1rem;
      }
    }
  }

  .company__links-content {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
  }
  .single-bottom-info__no-info {
    font-size: 0.9rem;
    padding: 0;
    margin: 3px 0 0 0;
    color: $color-heading-middle-grey;
    &.no-info--email,
    &.no-info--phone {
      svg {
        color: $primary-color-light-light;
        font-size: 1.2rem;
        margin-right: 5px;
      }
    }
    &.no-info--social {
      margin-left: 10px;
      svg {
        color: #02449ba2;
        font-size: 1.2rem;
        margin-right: 5px;
      }
    }
  }

  .company__section-linkedin {
    margin-top: 20px;
    .linkedin-item {
      svg {
        color: #0e76a8;
        font-size: 1.38rem;
      }
    }
    .company__linkedin-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }
  }
  // Email in bottom section
  .company__emails-h {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
  }
  .company__question-h {
    cursor: pointer;
    position: relative;
    transition: all linear 0.2s;
    .question-toggle {
      position: absolute;
      padding: 0;
      right: -24px;
      top: -3px;
      transition: all linear 0.2s;
    }

    &:hover {
      > span {
        color: rgb(158, 118, 150);
        transition: all linear 0.2s;
      }
      .question-toggle path {
        color: rgb(158, 118, 150);
        transition: all linear 0.2s;
      }
    }
  }
  .company__section-emails {
    margin-top: 25px;
    width: 100%;
    gap: 4px !important;
  }
  .company__section-phones {
    gap: 4px !important;
    min-width: 380px;
  }
}

// New button style bottom container links, website
.button-social {
  -webkit-box-shadow: $color-shadow-distr-new !important;
  -moz-box-shadow: $color-shadow-distr-new !important;
  box-shadow: $color-shadow-distr-new !important;
  .MuiButton-startIcon svg {
    font-size: 1.34rem !important;
  }
  &.button-social--website {
    .MuiButton-startIcon svg {
      color: rgba(72, 54, 97, 0.783);
    }
  }
  &.button-social--facebook {
    .MuiButton-startIcon svg {
      color: #02449ba2;
    }
  }
  &.button-social--instagram {
    .MuiButton-startIcon svg {
      color: #9b11baac;
    }
  }
  &.button-social--x {
    .MuiButton-startIcon svg {
      color: #474747;
    }
  }
}

.footer {
  height: 50px;
  margin: 0;
  padding: 0 3.5% 0 5%;
  box-sizing: border-box;
  display: inline-flex;
  position: relative;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  z-index: 15;
  font-size: 0.86rem;
  color: grey;
  border-left: 1px solid rgb(243 243 243);
  -webkit-box-shadow: 3px 3px 5px 3px rgba(34, 60, 80, 0.15);
  -moz-box-shadow: 3px 3px 5px 3px rgba(34, 60, 80, 0.15);
  box-shadow: 3px 3px 5px 3px rgba(34, 60, 80, 0.15);
}

// Div animation

.fade-appear {
  opacity: 0;
  animation: company_fade;
  transition: all linear 0.2s;
  animation-duration: 0.36s;
  animation-fill-mode: forwards;
  // animation-delay: 100ms;
}

.button-shadow-new {
  text-transform: none !important;
  -webkit-box-shadow: $color-shadow-distr-new !important;
  -moz-box-shadow: $color-shadow-distr-new !important;
  box-shadow: $color-shadow-distr-new !important;
}

// Animations
@keyframes company_fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes searchstart {
  0% {
    bottom: 100px;
    z-index: -1;
  }
  36% {
    bottom: -56px;
    z-index: -1;
  }
  40% {
    bottom: -56px;
    z-index: 1;
  }
  100% {
    bottom: -42px;
    z-index: 1;
  }
}
@keyframes searchhide {
  0% {
    bottom: -42px;
    z-index: 1;
  }
  30% {
    bottom: -56px;
    z-index: -1;
  }
  // 60%  {bottom: 100px; z-index: -1;}
  100% {
    bottom: 100px;
    z-index: -1;
  }
}
@keyframes signs-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes notification-anim {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}

// body > iframe {
//   display: none !important;
// }

// .react-error-overlay {
//   display: none !important;
// }
