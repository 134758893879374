@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;700;800&family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");

//  @font-face {
//   font-family: 'Lato', sans-serif;
//   src: local("Lato-Regular"),
//    url("./fonts/Lato-Regular.ttf") format("truetype");
//   font-weight: normal;
//  }

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-family:
    "Inter",
    "Roboto",
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// Colors
$primary-color: #8d5289;
$primary-color-light: #a86aa4;
$primary-color-dark: #7d4979;

$secondary-color: #5e6d9b;
$secondary-color-light: #cee2f5;

$primary-color-half: rgba(141, 82, 137, 0.5);
$primary-color-quarter: rgba(141, 82, 137, 0.25);
$primary-color-ten: rgba(141, 82, 137, 0.1);

$font-color-btn: #fff;
// $primary-color--light:

$primary-color--hover: #5a2436;
$color-heading-grey: rgb(67, 67, 67);
$color-heading-middle-grey: rgb(95, 95, 95);
$color-heading-light-grey: rgb(125, 125, 125);
$color-heading-super-light-grey: rgba(125, 125, 125, 0.676);
$color-heading-super-light-grey: rgb(125, 125, 125, 64%);
$color-background-main: rgb(219 226 213 / 17%);
$color-background-block: rgb(255, 255, 255);
$color-shadow-block: 4px 4px 12px 0px rgb(220 218 220);

$color-red: rgb(198 18 18 / 100%);
$color-red-75: rgb(198 18 18 / 75%);
$color-red-50: rgb(198 18 18 / 50%);
$color-red-25: rgb(198 18 18 / 25%);

$color-gold: rgb(239, 197, 59);

$color-background-outlined: rgba(141, 82, 137, 0.04);

$color-inactive: #b6b0b6; // Dublicate in App.js

$text-button-hover: rgba(243, 243, 243, 0.16);

$color-icon-blue-light: rgb(115 179 170);

.dashboard {
  display: flex;
  height: 100%;
  background-color: rgb(255 254 249);
  align-items: center;
  flex-direction: column;
  width: 100%;
  // height: 100vh;
  color: rgb(68, 68, 68);
}

.dashboard__menu {
  width: 100%;
  display: flex;
  gap: 4%;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 20px;
}

.dashboard__heading {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  font-size: 2.4rem;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.dashboard__content {
  width: 86%;
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 100px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  // width: 80%;
}

// Users Dashboard

.dashboard__filters {
  margin-bottom: 40px;
}

.dashboard__item {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 14px;
  min-height: 100px;
  height: auto;
  padding: 10px 2%;
  // background-color: #e5f2f2;
  background-color: #fff;
  // border: 1px solid rgb(199, 200, 201);
  border-radius: 5px;
  font-size: 1rem;
  box-shadow: 5px 7px 21px 4px rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 5px 7px 21px 4px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 5px 7px 21px 4px rgba(0, 0, 0, 0.14);
}

.dashboard__item--expired {
  display: none;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s linear;
}

// Tables
.dashboard__t-headings {
  display: flex;
  font-weight: bold;
  font-size: 0.8rem;
  color: $color-heading-middle-grey;
  width: 100%;
  justify-content: flex-start;
  padding: 0 1%;
}

// Clm
.column-value {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  word-break: break-all;
}

// Ids clm
.dashboard__h-ids,
.dashboard__ids {
  width: 3%;
}
.dashboard__ids {
  font-size: 0.84rem;
}

// Emails clm
.dashboard__h-emails,
.dashboard__emails {
  width: 12%;
  margin-right: 1.5%;
  font-size: 0.9rem;
  justify-content: center;
  p {
    margin: 2px 0;
  }
  p:first-child {
    font-weight: bold;
  }
}

// Plan clm
.dashboard__h-status,
.dashboard__status {
  width: 22%;
  margin-right: 1.5%;
  span {
    font-size: 0.9rem;
  }
}
.status__dates {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 3px;
  span {
    font-size: 0.8rem;
  }
}

.status__label {
  padding: 8px 10px;
  // border: 1px solid grey;
  border-radius: 4px;
  background-color: #5e6d9b;
  color: #fff;
  margin-top: 5px;
  margin-bottom: 5px;
}
.status__label--red {
  background-color: #9f4251;
  color: #fff;
}
.status__label--yellow {
  background-color: #d6d336;
  color: #fff;
}
.status__label--green {
  background-color: #4c9b3b;
  color: #fff;
}
.status__label--violet {
  background-color: #a14aa8;
  color: #fff;
}

.status__label-payment {
  margin: 0;
  background-color: rgb(235, 235, 235);
  color: grey;
  padding: 4px 5px;
  margin-left: 10px;
  border-radius: 2px;
}
// Dwwnld clm
.dashboard__h-downloads,
.dashboard__downloads {
  width: 8%;
  margin-right: 2%;
}
.dashboard__downloads {
  position: relative;
  p {
    margin: 0;
    margin-top: 5px;
  }
  .downloads__h-total-used {
    font-size: 0.75rem !important;
    font-weight: normal !important;
  }
  .downloads__total-used {
    font-size: 0.75rem !important;
    font-weight: normal !important;
  }
  font-size: 0.9rem;
  box-sizing: border-box;
  background-color: #677ec251;
  padding-left: 10px;
  div:first-child span {
    font-weight: bold;
  }
}

// Date clm
.dashboard__h-date,
.dashboard__date {
  width: 8%;
  margin-right: 1%;
}
.dashboard__h-calc,
.dashboard__calc {
  width: 8%;
  box-sizing: border-box;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  padding-left: 10px;
}
.dashboard__calc {
  background-color: rgba(103, 126, 194, 0.141);
  font-size: 0.75rem !important;
  font-weight: normal !important;
}

.dashboard__date {
  font-size: 0.8rem;
  justify-content: center;
  gap: 8px;
}
// Open emails clm
.dashboard__h-open-items,
.dashboard__open-items {
  width: 8%;
  font-weight: bold;
}
.dashboard__open-items {
  font-weight: normal;
  font-size: 1rem;
  padding-left: 10px;
  box-sizing: border-box;
}
.dashboard__h-credits,
.dashboard__credits {
  width: 8%;
  font-weight: bold;
}
.dashboard__credits {
  font-weight: normal;
  font-size: 0.9rem;
  padding-left: 10px;
  box-sizing: border-box;
  p {
    margin: 2px 0;
  }
}

// Open emails clm
.dashboard__h-open-emails,
.dashboard__open-emails {
  width: 8%;
  font-weight: bold;
}
.dashboard__open-emails {
  font-weight: normal;
  font-size: 1rem;
  padding-left: 10px;
  box-sizing: border-box;
}

// Open Links clm
.dashboard__h-open-links,
.dashboard__open-links {
  width: 8%;
  margin-right: 1.5%;
  font-weight: bold;
  p {
    margin: 0;
    margin-top: 5px;
  }
  .open-links__h-total-used {
    font-size: 0.75rem !important;
    font-weight: normal !important;
  }
  .open-links__total-used {
    font-size: 0.75rem !important;
    font-weight: normal !important;
  }
}
.dashboard__open-links {
  font-size: 0.9rem;
  box-sizing: border-box;
  background-color: rgba(112, 201, 123, 0.284);
  padding-left: 10px;
}

// Open Links clm
.dashboard__h-searches,
.dashboard__searches {
  width: 8%;
  font-weight: bold;
}
.dashboard__searches {
  font-weight: normal;
  font-size: 1rem;
  padding-left: 10px;
  box-sizing: border-box;
}

// Feedback clmn
.dashboard__h-feedback,
.dashboard__feedback {
  width: 100%;
}

.dashboard__feedback {
  border: 1px solid grey;
  background-color: #fff;
  margin: 8px 0;
  padding: 5px 10px;
  border-radius: 6px;
  color: #fff;
  background-color: #7d4979;
  &.dashboard__feedback--disabled {
    display: none;
    visibility: hidden;
  }
}

//////////////////////
/// Searches
.item-search {
  gap: 20px;
  display: flex;
  align-items: center;
  padding-left: 2%;
  padding-right: 2%;
}
.button-expand-searches {
  height: 40px;
  margin-left: auto !important;
}
.item-search--child {
  display: flex;
  visibility: visible;
  opacity: 1;
  transition: all 0.3s linear;

  width: 94%;
  margin-left: 6%;
  min-height: 80px;
  height: auto;

  background-color: #fff;
}
.item-search--hidden {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s linear;
}

.search__userid {
  width: 2%;
}
.search__email {
  width: 16%;
}
.search__region {
  width: 7%;
}
.search__country {
  width: 7%;
}
.search__province {
  width: 5%;
}
.search__type {
  width: 8%;
}
.search__size {
  width: 3%;
}
.search__date {
  width: 5%;
}
.search__prod_country {
  width: 5%;
}
.search__expand {
  width: 20%;
}

.dashboard__icon-top {
  position: absolute;
  top: 10px;
  right: 15px;
  svg {
    color: $color-icon-blue-light;
  }
}
.dashboard__icon-bottom {
  position: absolute;
  bottom: 10px;
  right: 15px;
  cursor: pointer;
  svg {
    color: $color-red;
  }
}
.blocker-item {
  visibility: visible;
  opacity: 1;
  position: absolute;
  background: red;
  color: #fff;
  padding: 0 10px;
  height: 24px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  bottom: -40px;
  left: -60px;
  z-index: 99;
  &.blocker-item--hidden {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}
.update-credits {
  position: absolute !important;
  bottom: 0;
  right: 0;
}
// Reports
.report__t-headings {
  display: flex;
  width: 50%;
  justify-content: space-evenly;
}
.report__item {
  display: flex;
  width: 50%;
  justify-content: space-evenly;
}

.report__h-ids,
.report__ids {
  width: 10%;
}
.report__h-company,
.report__company {
  width: 10%;
}
.report__h-user,
.report__user {
  width: 10%;
}
.report__h-date,
.report__date {
  width: 30%;
}
.report__h-action,
.report__action {
  width: 20%;
}
